/* eslint-disable camelcase */
import { getEnv, Instance, ModelActions, types } from 'mobx-state-tree';
import { TFunction } from '@valamis/i18n';
import { AxiosResponse } from 'axios';
import { getFlowWithErrorHandler } from './errorsStore';
import { ICategory, IFacetValue, IOptionsResultStore } from '../../types';
import { getBookmarkEntity } from '../../utilities';
import { getContentType, IContentType } from '../../types/content-type';

export const TimeScope = [
  {
    id: 'anytime',
    title: 'Anytime',
  },
  {
    id: 'last-hour',
    title: 'Last hour',
  },
  {
    id: 'last-day',
    title: 'Last 24 hours',
  },
  {
    id: 'last-week',
    title: 'Last week',
  },
  {
    id: 'last-month',
    title: 'Last month',
  },
  {
    id: 'last-year',
    title: 'Last year',
  },
];

export interface IFacetValueOpts {
  readonly showFrequency: boolean;
  readonly localizeValues?: boolean;
  readonly localizablePostfix?: string;
  readonly translate: TFunction;
}
export const FacetValue = types
  .model('facetValue', {
    name: types.string,
    term: types.string,
    frequency: types.optional(types.number, 0),
    settings: types.maybe(
      types.model({
        title: types.string,
        items: types.array(
          types.model({
            type: types.string,
            title: types.string,
            parameter: types.string,
            value: types.string,
            widget: types.string,
          })
        ),
      })
    ),
  })
  .actions(
    (self: Instance<typeof FacetValue>): ModelActions => ({
      setName(newName: string): void {
        self.name = newName;
      },
      setFreq(freq: number): void {
        self.frequency = freq;
      },
      getName(opts: IFacetValueOpts): string {
        const { localizablePostfix, localizeValues, translate, showFrequency } = opts;
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        const postfix = localizablePostfix ? ` ${translate(localizablePostfix)}` : '';
        const titleLabel: string = localizeValues ? translate(self.name.toLowerCase()) : self.name;

        return showFrequency
          ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `${titleLabel}${postfix} (${self.frequency})`
          : `${titleLabel}${postfix}`;
      },
    })
  );

export enum FacetValueType {
  undefined = 'undefined',
  category = 'category',
  course = 'course',
}

export const Facet = types
  .model('facets', {
    client_params: types.model({
      facet_order: types.optional(types.string, 'desc'),
      localizable_postfix: types.maybeNull(types.string),
      localize_title: types.optional(types.boolean, false),
      localize_values: types.optional(types.boolean, false),
      order_by: types.optional(types.union(types.string, types.undefined), undefined),
      value_count_at_start: types.optional(types.union(types.number, types.undefined), undefined),
      hide: types.boolean,
      order_number: types.number,
      title: types.string,
      type: types.optional(types.string, 'checkbox'),
      facet_value_type: types.optional(
        types.enumeration('valueType', Object.keys(FacetValueType)),
        FacetValueType.undefined
      ),
      vocabulary_name: types.maybe(types.string),
    }),
    values: types.optional(types.array(FacetValue), []),
    param_name: types.optional(types.string, ''),
    field_name: types.optional(types.string, ''),
  })
  .actions((self: Instance<typeof Facet>) => ({
    setValues(values: IFacetValue[]): void {
      self.values = values;
    },
  }));

export const MetaData = types.model('metaData', {
  size: types.maybe(types.string),
  format: types.maybe(types.string),
  openNewTab: types.maybe(types.string),
});

interface IViewSearchResultModel {
  readonly getConfig: IOptionsResultStore;
  readonly getContentType: IContentType;
}

export const SearchResult = types
  .model('SearchResult', {
    description: types.maybe(types.string),
    title: types.maybe(types.string),
    categories: types.maybe(
      types.array(
        types.model({
          id: types.number,
          title: types.string,
        })
      )
    ),
    ratingScore: types.maybe(types.string),
    viewsCount: types.maybe(types.string),
    previewCount: types.maybe(types.string),
    id: types.identifier,
    urn: types.maybe(types.string),
    imageSrc: types.maybe(types.string),
    course: types.maybe(
      types.model({
        id: types.number,
        name: types.string,
      })
    ),
    author: types.maybe(types.string),
    timestamp: types.maybe(types.string),
    className: types.string,
    link: types.maybe(types.string),
    shareableLink: types.maybe(types.string),
    redirect: types.maybe(types.string),
    previewUrl: types.maybe(types.string),
    bookmarkId: types.maybe(types.number),
    metadata: types.maybeNull(MetaData),
    duration: types.maybe(types.string),
    folderId: types.maybe(types.string),
    repositoryId: types.maybe(types.string),
    fileEntryId: types.maybe(types.string),
    permissions: types.optional(types.frozen<{ [k: string]: string }>(), {}),
    newPlayer: types.optional(types.boolean, false),
  })
  .views(
    (self): IViewSearchResultModel => ({
      get getConfig(): IOptionsResultStore {
        return getEnv(self).config;
      },
      get getContentType(): IContentType {
        return getContentType(self.className);
      },
    })
  )
  .actions((self: Instance<typeof SearchResult>): ModelActions => {
    const flow = getFlowWithErrorHandler(self.getConfig.errorsStore);

    return {
      getBookmark: flow(function* getBookmark() {
        const bookmarkEntity = getBookmarkEntity(self);
        yield self.getConfig.apiClient
          .get(self.getConfig.ApiUrls.getBookmark(bookmarkEntity.entityType, bookmarkEntity.entityId))
          .then((response: AxiosResponse): void => {
            if (response.data?.total > 0) self.setBookmarkId(response.data.records[0].id);
          });
      }),
      toggleBookmark: flow(function* toggleBookmark() {
        if (!self.bookmarkId) {
          yield self.getConfig.apiClient
            .post(self.getConfig.ApiUrls.toggleBookmark(), getBookmarkEntity(self))
            .then((response: AxiosResponse): void => {
              if (response.data) self.setBookmarkId(response.data.id);
            });
        } else {
          yield self.getConfig.apiClient
            .delete(self.getConfig.ApiUrls.toggleBookmark(self.bookmarkId))
            .then((): void => {
              delete self.bookmarkId;
            });
        }
      }),
      setBookmarkId: (id: number): void => {
        self.bookmarkId = id;
      },
      setLink: (newLink: string): void => {
        self.link = newLink;
      },
    };
  });

const PageModel = types.model('page', {
  number: types.number,
  start: types.number,
  selected: types.maybe(types.boolean),
});

export interface IPage extends Instance<typeof PageModel> {
  readonly number: number;
  readonly start: number;
  readonly selected: boolean | undefined;
}

export interface IPagingViews {
  readonly getActive: IPage;
  readonly getPagesCount: number;
}

interface IMetaViews {
  readonly getTotalHints: number;
  readonly getPagesCount: number;
  readonly getQueryTerms: string;
}

export const Paging = types
  .model('paging', {
    pages: types.optional(types.array(PageModel), []),
    currentPage: types.optional(types.number, 1),
  })
  .views(
    (self): IPagingViews => ({
      get getActive(): IPage {
        const first: IPage = { number: 1, start: 0, selected: true };
        const activePage = self.pages.find((page): boolean => {
          if (page.selected) return true;
          return false;
        });
        return activePage || first;
      },
      get getPagesCount(): number {
        return self.pages.length;
      },
    })
  )
  .actions(
    (self: Instance<typeof Paging>): ModelActions => ({
      setCurrentPage(number: number): void {
        self.getActive.selected = false;
        self.currentPage = number;
        const newActive = self.pages.find((page): boolean => page.number === number);
        if (newActive) {
          newActive.selected = true;
        }
      },
      getNextPage(number: number): IPage {
        return self.pages.find((page): boolean => page.number === number) || {};
      },
    })
  );

export const Meta = types
  .model('meta', {
    executionTime: types.string,
    totalHits: types.number,
    queryTerms: types.string,
    originalQueryTerms: types.optional(types.union(types.string, types.undefined), undefined),
    start: types.number,
    totalPages: types.number,
    querySuggestions: types.maybeNull(types.array(types.string)),
  })
  .views(
    (self): IMetaViews => ({
      get getTotalHints(): number {
        return self.totalHits;
      },
      get getPagesCount(): number {
        return self.totalPages;
      },
      get getQueryTerms(): string {
        return self.queryTerms;
      },
    })
  )
  .actions(
    (self: Instance<typeof Meta>): ModelActions => ({
      setTotal(total: number): void {
        self.totalHits = total;
      },
    })
  );

export const VocabularyModel = types
  .model('VocabularyModel', {
    id: types.number,
    title: types.string,
    categories: types.optional(
      types.array(
        types.model({
          id: types.number,
          title: types.string,
        })
      ),
      []
    ),
  })
  .actions((self: Instance<typeof VocabularyModel>) => ({
    addCategory(newCategory: ICategory): void {
      const categoryExists = self.categories.find((c: ICategory) => c.id === newCategory.id);
      if (!categoryExists) {
        self.setCategories([...self.categories, newCategory]);
      }
    },
    setCategories(categories: ICategory[]): void {
      self.categories = categories;
    },
  }));

import { theme, Toast, ToastProvider } from '@valamis/ui-components';
import React, { FunctionComponent } from 'react';
import { ThemeProvider } from 'styled-components';
import { initI18nTools } from '@valamis/i18n';
import { SEARCH_RESULTS_APP, TOAST_CONFIGURATION } from '../../consts';
import NewApp from '../components/app/NewApp';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SearchResultsApp: FunctionComponent<any> = (_props: any) => {
  initI18nTools(SEARCH_RESULTS_APP.I18N.NAMESPACE);
  return (
    <ThemeProvider theme={theme}>
      <ToastProvider
        // @ts-ignore, TODO Fix typescript ignore
        components={{ Toast }}
        autoDismissTimeout={TOAST_CONFIGURATION.AUTO_DISMISS_TIMEOUT}
        // @ts-ignore
        zIndex={TOAST_CONFIGURATION.Z_INDEX}
      >
        <NewApp />
      </ToastProvider>
    </ThemeProvider>
  );
};

export default SearchResultsApp;

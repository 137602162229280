import { types, getEnv } from 'mobx-state-tree';
import { SyntheticEvent } from 'react';
import { NSearchResultsApp } from '../../types';

interface IView {
  readonly config: NSearchResultsApp.IOptionsStore;
  readonly userId: number;
  readonly itemsOnPage: number;
  readonly userLocale: string;
  readonly portletTitle: string;
  readonly searchUrl: string;
  readonly isBookmarksEnabled: boolean;
  readonly isMessagingToolEnabled: boolean;
  readonly onNavigateBack?: (e: SyntheticEvent) => void;
}

const ConfigurationsStore = types.model('AppStore').views(
  (self): IView => ({
    get config(): NSearchResultsApp.IOptionsStore {
      return getEnv(self).config;
    },
    get userId(): number {
      return getEnv(self).config.userId;
    },
    get itemsOnPage(): number {
      return getEnv(self).config.itemsOnPage;
    },
    get userLocale(): string {
      return getEnv(self).config.userLocale || navigator.language;
    },
    get portletTitle(): string {
      return getEnv(self).config.portletTitle;
    },
    get searchUrl(): string {
      return getEnv(self).config.searchUrl;
    },
    get isBookmarksEnabled(): boolean {
      return getEnv(self).config.isBookmarksEnabled;
    },
    get isMessagingToolEnabled(): boolean {
      return getEnv(self).config.isMessagingToolEnabled;
    },
    get onNavigateBack(): ((e: SyntheticEvent) => void) | undefined {
      return getEnv(self).config.onNavigateBack;
    },
  })
);

export default ConfigurationsStore;

interface IEventBus {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch(event: string, params?: any): void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  on(event: string, callback: (data: any) => void): void;
  removeEvent(event: string): void;
}

const EVENT_BUS_NOT_AVAILABLE = 'Valamis Event Bus was not created.';

export const eventBus: IEventBus = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: (event: string, params?: any): void => {
    try {
      Valamis?.eventBus?.dispatch(event, params);
    } catch (e) {
      console.error(EVENT_BUS_NOT_AVAILABLE, e);
    }
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  on: (event: string, callback: (data: any) => void): void => {
    try {
      Valamis?.eventBus?.on(event, callback);
    } catch (e) {
      console.error(EVENT_BUS_NOT_AVAILABLE, e);
    }
  },

  removeEvent: (event: string): void => {
    try {
      Valamis?.eventBus?.removeEvent(event);
    } catch (e) {
      console.error(EVENT_BUS_NOT_AVAILABLE, e);
    }
  },
};

export enum SearchEvents {
  setExternalKeyword = 'search:set_external_search_keyword',
  mobileSearchBarOpen = 'search:mobileSearchbarOpen',
  runSearch = 'search:run_search',
  searchKeyword = 'search:search_keyword',
}

export enum MessagingEvents {
  createMessage = 'message:create',
}

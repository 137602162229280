
export const createExtensionIRI = (key: string): string => {
    const withNoLocation = (): string => {
      const port = (window.location.port ? `:${window.location.port}` : '')
      return `${window.location.protocol}//${window.location.hostname}${port}`
    }

    const baseIRI = window.location.origin
      ? window.location.origin
      : withNoLocation()

    return `${baseIRI}/valamis/${key}`;
}
/* eslint camelcase: 0 */
/* eslint-disable no-use-before-define */
import React, { FC } from 'react';
import { CheckboxGroup, RadioButtonGroup } from '@valamis/ui-components';
import { useT } from '@valamis/i18n';
import styled from 'styled-components';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import FacetSettings from './FacetSettings';
import { IFilterOption, IFacet, IFacetValue, IResultStore } from '../../../types';
import { IFacetValueOpts } from '../../stores';

const Wrapper = styled.div`
  padding: 15px 0;
`;

const Header = styled.div`
  color: ${(props): string => props.theme.palette.black};
  padding-bottom: 12px;
  line-height: 16px;
  font-weight: bold;
`;

interface IProps {
  readonly id: string | null;
  readonly facet: IFacet;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly onValueSelect: (entity: Record<string, any>, field: string, isMulti?: boolean) => void;
  readonly filter: string | string[] | undefined;
  readonly visibleCount: number | undefined;
  readonly resultStore: IResultStore;
}

const sortFilterOptions = (orderBy: string, facetOrder: string, a: IFilterOption, b: IFilterOption): number => {
  let comparison: boolean;
  switch (orderBy) {
    case 'alphabetical':
      comparison = a.title.toLowerCase() > b.title.toLowerCase();
      break;

    default:
      comparison = a.frequency > b.frequency;
      break;
  }

  if (facetOrder === 'asc') {
    return comparison ? 1 : -1;
  }
  return comparison ? -1 : 1;
};

const FacetFilter: FC<IProps> = (props: IProps): JSX.Element => {
  const { id, facet, onValueSelect, filter, visibleCount, resultStore } = props;
  const { t } = useT();
  const { title, localize_title, localize_values, localizable_postfix, type, order_by, facet_order } =
    facet.client_params;

  const facetTitleLabel = title ?? facet.field_name;
  const facetTitle: string = localize_title ? t(facetTitleLabel) : facetTitleLabel;

  const onSettingApplied = (value: IFacetValue): void => {
    // setting should only toggles parent facet, if the parent is not applied.
    const facetValues = resultStore.getCurrentFilters[facet.param_name];
    if (!facetValues || (Array.isArray(facetValues) && !facetValues.includes(value.term))) {
      const record = { id: value.term };
      if (type === 'checkbox') {
        onValueSelect(record, facet.param_name, true);
      } else {
        onValueSelect(record, facet.param_name, false);
      }
    }
  };

  const getNameOpts: IFacetValueOpts = {
    showFrequency: true,
    translate: t,
    localizeValues: localize_values,
    localizablePostfix: localizable_postfix,
  };
  const values = facet.values.map(
    (value: IFacetValue): IFilterOption => ({
      id: value.term,
      title: value.getName(getNameOpts),
      value: filter?.includes(value.term) || false,
      frequency: value.frequency,
      children: value.settings && (
        <FacetSettings settings={value.settings} onSettingApplied={(): void => onSettingApplied(value)} />
      ),
    })
  );

  const disableFacetSettings = (facetValue: IFacetValue): void => {
    facetValue.settings?.items.forEach((setting) => {
      resultStore.removeFilter(setting.parameter);
    });
  };

  // Sort only if ordering defined in facet clientparams configuration
  const sortedValues = order_by ? values.sort((a, b) => sortFilterOptions(order_by, facet_order, a, b)) : values;

  const headerAriaLabel = `${facetTitle} ${t('filter')}`;

  if (sortedValues.length < 1) {
    return <></>;
  }
  return (
    <>
      {type === 'checkbox' ? (
        <CheckboxGroup
          className="facet-filter"
          headerLabel={facetTitle}
          headerAriaLabel={headerAriaLabel}
          content={sortedValues}
          labelledbyId={id || facet.param_name}
          // @ts-ignore TODO Fix typescript ignore
          onOptionChange={(value: { id: string }): void => {
            // When the facet filter is going to be toggled off, all its settings should be disabled as well.
            const facetFilterRemoved = filter?.includes(value.id);
            const facetValue = facet.values.find((f) => f.term === value.id);
            if (facetFilterRemoved && facetValue?.settings) {
              disableFacetSettings(facetValue);
            }

            onValueSelect(value, facet.param_name, true);
          }}
          countRowPerPage={visibleCount}
          showMoreLabel={t('Show all')}
        />
      ) : (
        <Wrapper>
          <Header id={id || facetTitle} aria-label={headerAriaLabel}>
            {facetTitle}
          </Header>
          <RadioButtonGroup
            values={sortedValues.map((value) => value.title)}
            onValueSelect={(index: number): void => onValueSelect(values[index], facet.param_name)}
            selectedIndex={Math.max(
              values.findIndex((value) => value.value),
              0
            )}
            groupName={facetTitle}
            labelledbyId={facetTitle}
          />
        </Wrapper>
      )}
    </>
  );
};

export default compose<IProps, Record<string, unknown>>(inject('resultStore'), observer)(FacetFilter);

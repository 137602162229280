
import Tincan from 'tincanjs';
import {
  ILRSConfiguration,
  IXAPIStatement,
  ILRSClient,
  IXAPIActivityItem,
  IXAPIActor
} from "./types";

export class TincanWrapper implements ILRSClient{
  readonly LRS: Tincan.LRS;

  readonly actor: Tincan.Actor;

  constructor(lrsConfig: ILRSConfiguration, actor: IXAPIActor) {
    this.LRS = new Tincan.LRS({
      endpoint: lrsConfig.endpoint,
      version: lrsConfig.lrsVersion,
      auth: lrsConfig.auth,
      allowFail: false
    });
    this.actor = new Tincan.Agent(actor);
  }

  sendStatement(statement: IXAPIStatement): Promise<void> {
    return new Promise((resolve, reject) => {
      const tincanStatement = this.buildTincanStatement(statement)
      const onStatementSent = (err, _result): void => {
        if (err) {
          console.error("Failed to send statement", statement, err);
          reject();
        } else {
          resolve();
        }
      }
      this.LRS.saveStatement(tincanStatement, { callback: onStatementSent });
    });
  }

  buildTincanStatement(statement: IXAPIStatement): Tincan.Statement {
    return new Tincan.Statement(
      {
        actor: this.actor,
        verb: {
          id: `http://adlnet.gov/expapi/verbs/${statement.verb}`,
          display: { "en-US": statement.verb }
        },
        target: this.createActivity(statement.item)
      }
    );
  }

  // eslint-disable-next-line class-methods-use-this
  createActivity(item: IXAPIActivityItem): Tincan.Activity {
    return new Tincan.Activity({
      id: encodeURI(item.link),
      definition: {
        name: { "en-US": item.title },
        extensions: item.extensions
      }
    });
  }
}
import styled from 'styled-components';

export const Breakpoints = {
  tabletMaxWidth: `(max-width: 1023px)`,
  tabletMinWidth: `(min-width: 768px)`,
  mobileMaxWidth: `(max-width: 767px)`,
  mobileMinWidth: `(min-width: 320px)`,
};

export default styled.section`
  /* base application styles */
  padding: 20px;
  height: auto;
  margin: 0;
  background: ${(props): string => props.theme.base.color.background};
  font-family: ${(props): string => props.theme.base.font.family};
  color: ${(props): string => props.theme.palette.black};
  font-size: ${(props): string => props.theme.base.font.size}px;
  line-height: ${(props): string => props.theme.base.lineheight};
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @media ${Breakpoints.tabletMaxWidth}, ${Breakpoints.mobileMaxWidth} {
    padding-left: 0;
    padding-right: 0;
  }

  /* normalizing */
  ul {
    padding: 0;
    margin: 0;
  }

  a {
    text-decoration: none;
    cursor: pointer;

    &:active,
    &:focus {
      outline: none;
    }
  }

  input {
    display: block;
    font-family: ${(props): string => props.theme.base.font.family};
    font-size: ${(props): string => props.theme.base.font.size};
    font-weight: ${(props): string => props.theme.base.font.weight};
    border-radius: ${(props): string => props.theme.base.font.radius};
    outline: none;
    width: 100%;
  }
`;

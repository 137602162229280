import { I18N } from '../consts';

const getLiferayLanguageId = (): string | undefined =>
  // @ts-ignore
  window && window.Liferay && window.Liferay.ThemeDisplay.getLanguageId();

export const getInitialLanguage = (userLocale?: string): string => {
  const language = userLocale || getLiferayLanguageId();
  return (language && language.replace('_', '-')) || I18N.DEFAULT_LANGUAGE;
};

// @ts-ignore, TODO fix typescript ignore
export const translate = (key: string): string => {
  try {
    // @ts-ignore
    return window.Valamis.i18n.t(key);
  } catch (e) {
    console.error('Failed to translate key: ', key, e);
    return key;
  }
};
import { applySnapshot, getEnv, getSnapshot, Instance, ModelActions, types } from 'mobx-state-tree';
import { IXAPIStatement } from 'utilities/lrs/types';
import { AxiosResponse } from 'axios';
import { Facet, FacetValueType, Meta, Paging, SearchResult, VocabularyModel } from './models';
import {
  IOptionsResultStore,
  ISearchResult,
  IPaging,
  IFacet,
  IMeta,
  IFacetValue,
  ICourseData,
  ICategory,
  IVocabulary, UIState,
} from '../../types';
import { getFlowWithErrorHandler } from './errorsStore';
import {
  getDuration,
  getItemLink,
  getRedirectBuilder,
  parseDate,
  serializeQueryParameters,
} from '../../utilities';
import { IResponseErrorMessage } from '../../exceptions/responseException';
import { setUrlParams } from '../../utilities/parameters';
import { getLRSClient } from '../../utilities/lrs';
import ApiUrls from "../../api/apiUrls";

const storeName = 'ResultStore';

interface IView {
  readonly getConfig: IOptionsResultStore;
  readonly getResults: ISearchResult[];
  readonly getLoadingState: string;
  readonly getPaging: IPaging;
  readonly getFacets: IFacet[];
  readonly getMeta: IMeta;
  readonly showMobileFilters: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  readonly getCurrentFilters: Record<string, any>;
}

const defaultMeta: IMeta = {
  executionTime: '',
  totalHits: 0,
  queryTerms: '',
  originalQueryTerms: undefined,
  start: 0,
  totalPages: 1,
  querySuggestions: undefined,
};

const defaultPaging: IPaging = {
  pages: [],
  currentPage: 1,
};

export const OldResultStore = types
  .model(storeName, {
    meta: types.optional(Meta, defaultMeta),
    facets: types.optional(types.array(Facet), []),
    items: types.optional(types.array(SearchResult), []),
    courses: types.optional(
      types.array(
        types.model({
          id: types.number,
          name: types.string,
        })
      ),
      []
    ),
    vocabularies: types.optional(types.array(VocabularyModel), []),
    paging: types.optional(Paging, defaultPaging),
    showMobileView: types.optional(types.boolean, false),
    currentFilters: types.optional(types.frozen<{ [k: string]: string }>(), {}),
    loadingState: types.optional(types.enumeration('loadingState', Object.keys(UIState)), UIState.Initial),
  })
  .views(
    (self): IView => ({
      get getConfig(): IOptionsResultStore {
        return getEnv(self).config;
      },
      get getResults(): ISearchResult[] {
        return self.items;
      },
      get getLoadingState(): string {
        return self.loadingState;
      },
      get getPaging(): IPaging {
        return self.paging;
      },
      get getFacets(): IFacet[] {
        return self.facets;
      },
      get getMeta(): IMeta {
        return self.meta;
      },
      get showMobileFilters(): boolean {
        return self.showMobileView;
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      get getCurrentFilters(): Record<string, any> {
        return self.currentFilters;
      },
    })
  )
  .actions((self: Instance<typeof OldResultStore>): ModelActions => {
    const flow = getFlowWithErrorHandler(self.getConfig.errorsStore);

    return {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fetchResults: flow(function* fetchResults(params: Record<string, any>, init?: boolean): IterableIterator<void> {
        try {
          self.setLoadingState(UIState.Pending);

          if (init) {
            // @ts-ignore
            yield self.initVocabularies().then((categoryPromises) => Promise.all(categoryPromises));
            self.clearFilters();
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const resp: any = yield self.getConfig.apiClient
            .get(self.getConfig.ApiUrls.results, {
              params,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              paramsSerializer: (parameters: Record<string, any>) => serializeQueryParameters(parameters),
            })
            .catch((e: IResponseErrorMessage) => Promise.resolve(e.status));

          const redirectBuilder = getRedirectBuilder(params, self.getConfig.renderUrl);

          if (resp?.data?.items) {
            // @ts-ignore
            yield Promise.all(self.fetchCourses(resp.data.items)).then((courses: ICourseData[]) =>
              courses.forEach((c) => c && self.addCourse(c))
            );

            // @ts-ignore
            yield Promise.all(self.fetchCategories(resp.data.items)).then((categories: ICategory[]) =>
              categories.forEach((c) => {
                if (c) {
                  const vocabulary = self.vocabularies.find((voc: IVocabulary) => voc.id === c.vocabularyId);
                  vocabulary && self.addVocabularyCategory(vocabulary, c);
                }
              })
            );

            self.setItems(resp.data.items, redirectBuilder);

            // @ts-ignore
            yield Promise.all(self.resolveFacets(resp.data.facets)).then((facets: IFacet[]) => self.setFacets(facets));

            self.setMeta(resp.data.meta);
            self.setPaging(resp.data.paging);
            self.setCurrentFilters(params);

            const promises: Promise<void>[] = [];
            if (self.getConfig.isBookmarksEnabled) {
              self.items.forEach(
                (item: typeof SearchResult) => item.getContentType.bookmarkableName && promises.push(item.getBookmark())
              );
            }

            // @ts-ignore
            yield Promise.all(promises);
            self.setLoadingState(UIState.Done);

            setUrlParams(params);
          } else {
            console.error(`${storeName} error: no data`);
            self.setLoadingState(UIState.Error);
          }
        } catch (e) {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          console.error(`${storeName} error`, e);
          self.setLoadingState(UIState.Error);
        }
      }),
      initVocabularies(): Promise<void> {
        return self.getConfig.apiClient
          .get(ApiUrls.vocabularies)
          .then((vocabulariesResponse) =>
            vocabulariesResponse.data.map((vocabulary: { id: number; title: string }) => vocabulary)
          )
          .then((vocabularies: { id: number; title: string }[]): Promise<void>[] =>
            vocabularies.flatMap((voc): Promise<void>[] =>
              self.getConfig.apiClient
                .get(`${ApiUrls.getCategories(voc.id)}`)
                .then((categoriesResponse: AxiosResponse<{ id: string; title: string }[]>): void => {
                  categoriesResponse.data.forEach((c) => {
                    const vocabulary = { id: voc.id, title: voc.title, categories: [] };
                    const newCategory: ICategory = { id: parseInt(c.id), title: c.title, vocabularyId: voc.id };
                    self.addVocabularyCategory(vocabulary, newCategory);
                  });
                })
            )
          );
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resolveFacets(items: any[]): Promise<IFacet>[] {
        // Only replace facet in store if an update is coming from search response.
        const newFacets: IFacet[] = self.facets.map((facet) => {
          const foundNewItem = items.find((item) => item.param_name === facet.param_name);
          if (foundNewItem && foundNewItem.values.length > 0) {
            return foundNewItem;
          }
          if (foundNewItem?.values.length === 0) {
            facet.values.map((fValue: IFacetValue) => fValue.setFreq(0));
          }
          return facet;
        });
        const facets: IFacet[] = newFacets.length > 0 ? newFacets : items;

        const facetPromises: Promise<IFacet>[] = facets.flatMap((f: IFacet) => {
          const facetValueType: FacetValueType = f.client_params.facet_value_type;
          if (facetValueType === FacetValueType.category && f.client_params.vocabulary_name) {
            const vocabularyName: string = f.client_params.vocabulary_name;
            const newValuePromises: Promise<IFacetValue>[] = f.values
              .map((fValue: IFacetValue): IFacetValue | null => {
                const foundCategory: ICategory | undefined = self.vocabularies
                  .find((voc: IVocabulary) => voc.title === vocabularyName)
                  ?.categories.find((c) => c.id === parseInt(fValue.term));
                if (!foundCategory) {
                  return null;
                }
                const updatedValue: IFacetValue = { ...fValue, name: foundCategory.title };
                return updatedValue;
              })
              .filter((value: IFacetValue | null) => value !== null)
              .map((facetValue: IFacetValue) => Promise.resolve(facetValue));
            return Promise.all(newValuePromises).then((facetValues) => {
              const updatedFacet: IFacet = { ...f, values: facetValues };
              return updatedFacet;
            });
          }
          if (facetValueType === FacetValueType.course) {
            const newValuePromises: Promise<IFacetValue>[] = f.values.map(
              (fValue: IFacetValue): Promise<IFacetValue> => {
                const foundCourse: ICourseData = self.courses.find((c) => c.id === parseInt(fValue.term));
                if (!foundCourse) {
                  return self.fetchCourse(parseInt(fValue.term)).then((course: ICourseData) => {
                    self.addCourse(course);
                    const updatedValue: IFacetValue = { ...fValue, name: course.name };
                    return updatedValue;
                  });
                }
                const updatedValue: IFacetValue = { ...fValue, name: foundCourse.name };
                return Promise.resolve(updatedValue);
              }
            );
            return Promise.all(newValuePromises).then((facetValues) => {
              const updatedFacet: IFacet = { ...f, values: facetValues };
              return updatedFacet;
            });
          }
          return Promise.resolve(f);
        });

        return facetPromises;
      },

      setFacets(newFacets: IFacet[]): void {
        const updatedFacets =
          self.facets.length > 0
            ? newFacets.map((newFacet) => {
              const alreadyStoredFacet = self.facets.find((f) => f.param_name === newFacet.param_name);
              if (alreadyStoredFacet) {
                applySnapshot(alreadyStoredFacet, newFacet);
                return alreadyStoredFacet;
              }
              return Facet.create(newFacet);
            })
            : newFacets;
        self.facets = updatedFacets;
      },
      setItems(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        responseItems: any[],
        redirectBuilder: (itemLink: string, itemRedirect: string, index: number) => string
      ): void {
        const parsedItems = responseItems
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .map((item: any, index: number): ISearchResult | null => {
            // XXX The following line is for backwards compatibility when search api does not return plain courseId
            const courseId: number = item.courseId || parseInt(item.course.id);
            const course = self.courses.find((c: ICourseData): boolean => c.id === courseId);
            if (!course) {
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              console.warn(`No course with id ${courseId} found in store , when parsing search result ${item}`);
            }
            // XXX The following line is for backwards compatibility when search api does not return plain categoryIds
            const categoryIds: number[] = item?.categoryIds || item?.categories?.map((c) => parseInt(c.id)) || [];
            const categories: ICategory[] = categoryIds.flatMap((categoryId) =>
              self.vocabularies.reduce((acc: ICategory[], voc: IVocabulary) => {
                const foundCategory = voc.categories.find((c) => c.id === categoryId);
                // @ts-ignore
                return foundCategory ? [...acc, getSnapshot(foundCategory)] : acc;
              }, [])
            );
            // @ts-ignore, TODO Fix ts-ignore
            return {
              ratingScore: parseFloat(item.ratings) > 0 ? item.ratings : '',
              previewCount: item.previewCount || '',
              course: course ? getSnapshot(course) : undefined,
              categories,
              author: item.userName || '',
              link: getItemLink(item, redirectBuilder, index),
              shareableLink: item.shareableLink || '',
              title: item.title || '',
              title_raw: item.title_raw || '',
              id: item.id,
              timestamp: item.date ? parseDate(item.date, self.getConfig.userLocale) : '',
              className: item.type || item.className,
              description: item.description || '',
              previewUrl: item.previewUrl ? encodeURI(item.previewUrl) : '',
              imageSrc: item.imageSrc || '',
              redirect: item.redirect || '',
              metadata: item.metadata || {},
              duration: getDuration(item.metadata),
              repositoryId: item.repositoryId,
              folderId: item.folderId,
              fileEntryId: item.fileEntryId,
              permissions: item.permissions,
            };
          })
          .filter((item) => item !== null);
        self.items = parsedItems;
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fetchCourses: (searchResults: any[]): Promise<ICourseData | null>[] => {
        const uniqueCourseIds = searchResults.reduce((acc: number[], item) => {
          const currentCourseId: number = item.courseId || parseInt(item.course.id);
          if (acc.includes(currentCourseId)) return acc;
          return [...acc, currentCourseId];
        }, []);
        return uniqueCourseIds.map((courseId) => self.fetchCourse(courseId));
      },
      fetchCourse(courseId: number): Promise<ICourseData | null> {
        const storedCourse = self.courses.find((c: ICourseData) => c.id === courseId);
        if (storedCourse) {
          return Promise.resolve(storedCourse);
        }
        return self.getConfig.apiClient
          .get(`${ApiUrls.courses}/${courseId}`)
          .then(
            (response: AxiosResponse<{ groupId: string; descriptiveName: string }>): ICourseData => ({
              id: parseInt(response.data.groupId),
              name: response.data.descriptiveName,
            })
          )
          .catch((error) => {
            console.error(`Getting course with id ${courseId} failed`, error);
            return null;
          });
      },
      addCourse(newCourse: ICourseData): void {
        const courseExists = self.courses.find((course: ICourseData) => course.id === newCourse.id);
        if (!courseExists) {
          self.courses = [...self.courses, newCourse];
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fetchCategories: (searchResults: any[]): Promise<ICategory | null>[] => {
        const uniqueCategoryIds = searchResults.reduce((acc: number[], item) => {
          const currentCategoryIds: number[] = item?.categoryIds || item?.categories?.map((c) => parseInt(c.id)) || [];
          const newCategoryIds = currentCategoryIds.flatMap((id) => {
            if (acc.includes(id)) return [];
            return [id];
          });
          return [...acc, ...newCategoryIds];
        }, []);
        return uniqueCategoryIds.map((categoryId) => self.fetchCategory(categoryId));
      },
      fetchCategory(categoryId: number): Promise<ICategory | null> {
        const foundCategory = self.vocabularies
          .flatMap((v: IVocabulary) => [...v.categories])
          .find((category: ICategory) => category.id === categoryId);
        if (foundCategory) {
          return Promise.resolve(foundCategory);
        }
        return self.getConfig.apiClient
          .get(`${ApiUrls.getLiferayCategory}/${categoryId}`)
          .then(
            (response: AxiosResponse<{ categoryId: string; name: string; vocabularyId: string }>): ICategory => ({
              id: parseInt(response.data.categoryId),
              title: response.data.name,
              vocabularyId: parseInt(response.data.vocabularyId),
            })
          )
          .catch((error) => {
            console.error(`Getting category with id ${categoryId} failed`, error);
            return null;
          });
      },
      addVocabularyCategory(vocabulary: IVocabulary, newCategory: ICategory): void {
        if (self.vocabularies.length === 0) {
          const newVocabularies: IVocabulary[] = [{ ...vocabulary, categories: [newCategory] }];
          self.vocabularies = newVocabularies;
        } else {
          const foundVocabulary: IVocabulary = self.vocabularies.find((voc: IVocabulary) => voc.id === vocabulary.id);
          if (foundVocabulary) {
            foundVocabulary.addCategory(newCategory);
          } else {
            const updatedVocabularies = [...self.vocabularies, { ...vocabulary, categories: [newCategory] }];
            self.vocabularies = updatedVocabularies;
          }
        }
      },
      setPaging(paging: IPaging): void {
        self.paging = paging;
      },
      setMeta(meta: IMeta): void {
        self.meta = meta;
      },
      setLoadingState(state: string): void {
        self.loadingState = state;
      },
      resetStore(): void {
        self.items.splice(0, self.items.length);
        self.meta.setTotal(0);
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setCurrentFilters(filters: Record<string, any>): void {
        self.currentFilters = { ...self.currentFilters, ...filters };
      },
      removeFilter(filterName: string): void {
        // eslint-disable-next-line
        const curFilters: Record<string, any> = self.currentFilters;
        const newFilters = Object.entries(curFilters).reduce((acc, [name, value]) => {
          if (filterName === name) return { ...acc };
          return { ...acc, [name]: value };
        }, {});
        self.currentFilters = newFilters;
      },
      clearFilters(): void {
        self.currentFilters = {};
      },
      toggleMobileView(flag: boolean): void {
        self.showMobileView = flag;
      },
      sendXAPIStatement(statement: IXAPIStatement): void {
        // eslint-disable-next-line
        getLRSClient(self.getConfig.apiClient).then((lrsClient) => {
          // eslint-disable-next-line
          lrsClient.sendStatement(statement);
        });
      },
    };
  });

/* eslint-disable no-use-before-define */
import React, { FunctionComponent } from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from '@valamis/ui-components';
import AppWrapper from './AppWrapper';
import ErrorManager from '../common/error-manager/ErrorManager';
import { IAppContainerProps } from '../../../types';

const AppContainer: FunctionComponent<IAppContainerProps> = (props: IAppContainerProps): JSX.Element => {
  const { children } = props;
  return (
    <ThemeProvider theme={theme}>
      <ErrorManager>
        <AppWrapper>{children}</AppWrapper>
      </ErrorManager>
    </ThemeProvider>
  );
};

export default AppContainer;

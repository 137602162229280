/* eslint-disable no-use-before-define */
import React, { FC } from 'react';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { Pagination } from '@valamis/ui-components';
import { Trans, useT } from '@valamis/i18n';
import styled from 'styled-components';
import ResultList from './ResultList';
import { IAppOptions, ISearchResult, IResultStore } from '../../../types';
import Empty from './Empty';
import { RESULT_LIST_ID } from '../../../consts';

interface IProps {
  readonly configurationsStore: IAppOptions;
  readonly resultStore: IResultStore;
}

interface ISpellCheckProps {
  readonly queryTerms: string;
  readonly originalQueryTerms: string;
}

const SpellCheckIndication: FC<ISpellCheckProps> = (props: ISpellCheckProps): JSX.Element => {
  // eslint-disable-next-line prefer-const
  let { queryTerms, originalQueryTerms } = props;

  const Wrapper = styled.span`
    display: block;
    font-size: 16px;
    line-height: 20px;
    margin-top: 15px;
  `;

  originalQueryTerms = originalQueryTerms.trim();
  return (
    <Wrapper role="alert" aria-live="polite">
      <Trans i18nKey="spell-check-label">
        {/* eslint-disable-next-line react/no-unescaped-entities */}
        The search for “<strong>{{ originalQueryTerms }}</strong>” didn't produce any result. Showing results for “
        <strong>{{ queryTerms }}</strong>”.
      </Trans>
    </Wrapper>
  );
};

const ResultListContainer: FC<IProps> = (props: IProps): JSX.Element => {
  const { resultStore, configurationsStore } = props;
  const paging = resultStore.getPaging;
  const meta = resultStore.getMeta;
  const page = paging.getActive;
  const pageCount = paging.getPagesCount;
  const searchResults: ISearchResult[] = resultStore.getResults;

  const { t } = useT();

  const onPageChanged = (newPageNumber: number): void => {
    const newPage = paging.getNextPage(newPageNumber);
    const params = { ...resultStore.getCurrentFilters, ...{ start: newPage.start } };

    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    paging.setCurrentPage(newPage.number);
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    resultStore.fetchResults(params);
  };

  return searchResults.length > 0 ? (
    <div id={RESULT_LIST_ID} tabIndex={0} role="listbox" aria-label={t('Search results')}>
      {meta.originalQueryTerms && (
        <SpellCheckIndication queryTerms={meta.queryTerms} originalQueryTerms={meta.originalQueryTerms} />
      )}
      <ResultList
        results={resultStore.getResults}
        isBookmarksEnabled={configurationsStore.isBookmarksEnabled}
        isMessagingToolEnabled={configurationsStore.isMessagingToolEnabled}
        sendXAPIStatement={resultStore.sendXAPIStatement}
      />
      {pageCount > 1 && (
        <Pagination
          totalPages={pageCount}
          page={page.number}
          onPageChanged={onPageChanged}
          pageButtonAriaLabel={t('Page')}
          activePageIndicatorAriaLabel={t('Active page')}
          ellipsisButtonAriaLabel={t('Nearest pages')}
          previousPageLinkAriaLabel={t('Previous page')}
          nextPageLinkAriaLabel={t('Next page')}
        />
      )}
    </div>
  ) : (
    <Empty />
  );
};

export default compose<IProps, unknown>(inject('resultStore', 'configurationsStore'), observer)(ResultListContainer);

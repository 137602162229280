/* eslint-disable no-use-before-define */
import React from 'react';
import { useT } from '@valamis/i18n';
import styled from 'styled-components';
import { Centerer, Icons } from '@valamis/ui-components';

const ErrorWrapper = styled(Centerer)`
  width: 100%;
`;

const ErrorStatus = styled.div`
  font-size: 24px;
  margin-top: 40px;
  margin-bottom: 20px;
  font-weight: bold;
`;

const BackButton = styled.div`
  font-size: 16px;
  margin: auto;
  width: fit-content;
  text-decoration: underline;
  color: ${(props): string => props.theme.palette.primary};
  cursor: pointer;

  &:focus {
    box-shadow: ${(props): string => props.theme.accessibility.ariaFocusHighlight};
    outline: none;

    &:not(.focus-visible) {
      box-shadow: none;
    }
  }
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const ErrorPage = (_): JSX.Element => {
  const { t } = useT();

  return (
    <ErrorWrapper>
      <Icons.ValboSad />
      <ErrorStatus>{t('Error')}</ErrorStatus>
      <BackButton
        onClick={(): void => window.history.back()}
        onKeyDown={(): void => window.history.back()}
        role="link"
        tabIndex={0}
        data-marker="return-btn"
      >
        {t('Return to previous page')}
      </BackButton>
    </ErrorWrapper>
  );
};

export default ErrorPage;

import { FunctionComponent } from 'react';
import { inject, observer } from 'mobx-react';
import { withToastManager } from '@valamis/ui-components';
import { compose } from 'recompose';
import { IToastManager, IErrorsStore } from '../../../../types';

interface IProps {
  readonly children: JSX.Element;
  readonly toastManager: IToastManager;
  readonly errorsStore: IErrorsStore;
}

const ErrorManager: FunctionComponent<IProps> = (props: IProps): JSX.Element => {
  const { errorsStore, children, toastManager } = props;
  if (errorsStore.hasError) {
    toastManager.add(errorsStore.pullError(), {
      appearance: 'error',
      autoDismiss: false,
    });
  }

  return children;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default compose<IProps, {}>(withToastManager, inject('errorsStore'), observer)(ErrorManager);

import React, { FC } from 'react';
import { CardItem } from '@valamis/ui-components';
import { compose } from 'recompose';
import { observer } from 'mobx-react';
import { shareMenuItems as shareMenuItemsType, ISearchResult } from '../../types/types';

interface IItemProps {
  entity: ISearchResult;
  readonly actions: Record<string, unknown>;
  readonly shareMenuItems: shareMenuItemsType[];
}

const Item: FC<IItemProps> = (props: IItemProps): JSX.Element => {
  const { entity, actions, shareMenuItems } = props;
  const cardEntity = {
    ...entity,
    course: entity?.course?.name || '',
    categories: entity?.categories?.map((c) => c.title) || [],
    type: entity.getContentType.typeName,
    ratingCount: entity.ratings,
    author: entity.author,
  };
  return <CardItem entity={cardEntity} actions={actions} shareMenuItems={shareMenuItems} />;
};

export const ResultItem = compose<IItemProps, IItemProps>(observer)(Item);
import styled from 'styled-components';
import moment from 'moment';
import { buttonFocusStyle } from '@valamis/ui-components';
import { TFunction } from '@valamis/i18n';
import { IBookmarkEntity, ISearchResult, ISharingEntity } from '../types';
import {
  DEFAULT_DATE_FORMAT,
  KEYWORD_PARAMETER_KEY,
  redirectUrlParamBlacklist,
  RESULT_ITEM_ID_PARAM,
  SEARCH_OPEN_PARAM,
} from '../consts';
import { translate } from '../i18n/custom';
import { getContentType, IContentType } from '../types/content-type';

export const transformLangFormat = (lang: string): string => lang.replace(/_/g, '-');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getStringSearchParams = (params: Record<string, any>): string => {
  // eslint-disable-next-line prefer-const
  let searchParams = new URLSearchParams('');
  const removeEmptyParams = (query: string): string => query.replace(/[^=&]+=(?:&|$)/g, '');

  Object.keys(params).forEach((key) => {
    if (typeof params[key] === 'object') params[key].forEach((value) => !!value && searchParams.append(key, value));
    else searchParams.append(key, params[key]);
  });

  const stringParams = searchParams.toString() ? `?${searchParams.toString()}` : '';

  return removeEmptyParams(stringParams).replace(/&$/, '');
};

export const getDownloadLessonUrl = (lessonId: number, courseId: number): string =>
  `/delegate/files/export/?action=DOWNLOAD&contentType=package&id=${lessonId}&courseId=${courseId}`;

export const stripHtml = (s: string): string => s.replace(/(<([^>]+)>)/gi, '');

export const getBookmarkEntity = (entity: ISearchResult): IBookmarkEntity => {
  const sanitizedTitle = stripHtml(entity.title);
  const { bookmarkableName } = entity.getContentType;
  if (!bookmarkableName) {
    throw new Error(`Unable to get bookmarkable name for ${entity.getContentType.className}`);
  }
  return {
    entityType: bookmarkableName,
    entityId: parseInt(entity.id),
    title: sanitizedTitle,
  };
};

export const getSharingEntity = (entity: ISearchResult, t: TFunction): ISharingEntity => {
  const sanitizedTitle = stripHtml(entity.title);
  const sanitizedDescription = stripHtml(entity.description);
  if (!entity.course) {
    console.warn('Entity does not have course data', entity);
  }
  const contentType: IContentType = getContentType(entity.className);
  return {
    course: entity.course || { id: -1, name: 'Unknown course' },
    title: sanitizedTitle,
    description: sanitizedDescription,
    imageSrc: entity.imageSrc,
    link: entity.link,
    type: t(contentType.translationLabel),
  };
};

// TODO Date formatting should come from app configuration, later.
export const parseDate = (
  dateString: string,
  locale: string,
  formatting: Record<string, string> = DEFAULT_DATE_FORMAT
): string => {
  const date = moment(dateString, 'DD-MMM-YYYY').toDate(); // Format from backend 12-Apr-2021
  if (locale.includes('_')) {
    return date.toLocaleDateString(locale.replace('_', '-'), formatting);
  }
  return date.toLocaleDateString(locale, formatting);
};

export const getResultItemDomId = (resultItemIndex: number): string => `result-item-${resultItemIndex}`;

export const getRedirectBuilder = (
  params: Record<string, unknown>,
  appRenderUrl: string
): ((itemLink: string, itemRedirect: string, index: number) => string) => {
  // Remove possible duplicate and item id params
  // eslint-disable-next-line no-param-reassign
  params[RESULT_ITEM_ID_PARAM] && delete params[RESULT_ITEM_ID_PARAM];

  // eslint-disable-next-line no-use-before-define
  const queryString = serializeQueryParameters(params, redirectUrlParamBlacklist);
  const buildRedirectTo = (index: number): string => {
    const itemId = getResultItemDomId(index);
    return escape(
      `${appRenderUrl.split('?')[0]}?${queryString}&${SEARCH_OPEN_PARAM}=true&${RESULT_ITEM_ID_PARAM}=${itemId}`
    );
  };

  return (itemLink: string, itemRedirect: string, index: number): string => {
    const redirectParam = itemRedirect.split('=')[0];
    const redirectTo = buildRedirectTo(index);
    return `${itemLink}${redirectParam}=${redirectTo}&referer=${redirectTo}`;
  };
};

export const getItemLink = (
  resultItem: ISearchResult,
  buildRedirect: (link: string, redirect: string, index: number) => string,
  index: number
): string =>
  resultItem.redirect
    ? `${buildRedirect(resultItem.link, resultItem.redirect, index)}`
    : (isUrlEncoded(resultItem.link) ? resultItem.link : encodeURI(resultItem.link)) || '';

export const isUrlEncoded = (uri: string): boolean => uri !== decodeURIComponent(uri || '');

export const getFileDownloadLink = (entity: ISearchResult): string => {
  const src = entity.imageSrc;
  if (src) {
    return src.match(/&[a-z]*Thumbnail=1/g)
      ? src.replace(/&[a-z]*Thumbnail=1/g, '&download=1')
      : `${encodeURI(entity.link)}?download=1`;
  }
  return `${encodeURI(entity.link)}?download=1`;
};

export const serializeQueryParameters = (params: Record<string, unknown>, blacklisted?: string[]): string =>
  Object.entries({ ...params })
    .map(([key, value]) => {
      if (blacklisted && blacklisted.includes(key)) return null;
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      if (Array.isArray(value)) return value.map((v) => `${key}=${v}`).join('&');
      if (key === KEYWORD_PARAMETER_KEY) {
        // @ts-ignore
        return `${key}=${encodeURIComponent(value)}`;
      }
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return `${key}=${value}`;
    })
    .flatMap((item) => (item ? [item] : []))
    .join('&');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getDuration = (metadata: Record<string, any> | undefined): string =>
  metadata
    ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      (metadata.duration && `${metadata.duration} ${translate('min')}`) || ''
    : '';

export const copyToClipboard = (text: string, fallbackLabel: string): void => {
  // eslint-disable-next-line @typescript-eslint/no-floating-promises, no-alert
  navigator.clipboard ? navigator.clipboard.writeText(text) : window.prompt(fallbackLabel, text);
};

export const scrollIntoView = (elementId: string, offsetTop: number): void => {
  const elementPosition = document.getElementById(elementId)?.getBoundingClientRect().top;
  elementPosition &&
    window.scrollTo({
      top: elementPosition - offsetTop,
      behavior: 'smooth',
    });
};

export const ForScreenReader = styled.span`
  position: absolute;
  width: 0px;
  height: 0px;
  opacity: 0;
`;

export const SkipToLink = styled.a`
  line-height: 18px;
  color: ${(props): string => props.theme.palette.primary};
  padding: 4px 7px;
  border-radius: 3px;

  opacity: 0;
  -webkit-transition: opacity 0.1s ease-in-out;
  -moz-transition: opacity 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out;

  &:focus {
    opacity: 1;
  }

  ${buttonFocusStyle}

  &:hover {
    background: none;
  }
`;

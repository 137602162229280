import { types, ModelActions, Instance, flow, decorate } from 'mobx-state-tree';
import { FlowReturn } from 'mobx-state-tree/dist/core/flow';
import { IErrorsStore } from '../../types';

interface IView {
  readonly hasError: boolean;
}

const ErrorsStore = types
  .model('ErrorsStore', {
    error: types.optional(types.string, ''),
  })
  .views(
    (self): IView => ({
      get hasError(): boolean {
        return self.error !== '';
      },
    })
  )
  .actions(
    (self: Instance<typeof ErrorsStore>): ModelActions => ({
      setError(error: string): void {
        self.error = error;
      },
      pullError(): string {
        const { error } = self;
        self.error = '';
        return error;
      },
      errorHandlerActionDecorator(call, next): void {
        try {
          next(call);
        } catch (e) {
          self.setError('Failed');
          throw e;
        }
      },
    })
  );
// return decorated method MST flow
export function getFlowWithErrorHandler(
  errorsStore: IErrorsStore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): <R, Args extends any[]>(
  generator: (...args: Args) => IterableIterator<R>
) => (...args: Args) => Promise<FlowReturn<R>> {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return function flowWithErrorHandler<R, Args extends any[]>(
    generator: (...args: Args) => IterableIterator<R>
  ): (...args: Args) => Promise<FlowReturn<R>> {
    // @ts-ignore
    return decorate(errorsStore.errorHandlerActionDecorator, flow(generator));
  };
}

export default ErrorsStore;

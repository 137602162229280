export interface IContentType {
  readonly className: string;
  readonly bookmarkableName?: string;
  readonly isShareable?: boolean;
  readonly isDownloadable?: boolean;
  readonly isPreviewable?: boolean;

  /**
   * Used for translating content type name
   */
  readonly translationLabel: string;

  /**
   * Used in sharing card for defining thumbnail
   */
  readonly typeName: string;
}

export const Assignment: IContentType = {
  className: 'com.arcusys.valamis.assignment.model.assignment',
  bookmarkableName: 'assignment',
  isShareable: true,
  translationLabel: 'assignment',
  typeName: 'assignments',
};

export const Lesson: IContentType = {
  className: 'com.arcusys.valamis.lesson.model.lesson',
  bookmarkableName: 'lesson',
  isShareable: true,
  isDownloadable: true,
  translationLabel: 'valamis-lesson',
  typeName: 'lessons',
};

export const LearningPath: IContentType = {
  className: 'com.arcusys.valamis.learningpath.model.learningpath',
  bookmarkableName: 'learning-path',
  isShareable: true,
  translationLabel: 'valamis-learning-path',
  typeName: 'learning paths',
};

export const TrainingEvent: IContentType = {
  className: 'com.liferay.calendar.model.calendarbooking',
  bookmarkableName: 'training-event',
  isShareable: true,
  translationLabel: 'valamis-training-event',
  typeName: 'training events',
};

export const Course: IContentType = {
  className: 'com.valamis.course.model.course',
  bookmarkableName: 'course',
  isShareable: true,
  translationLabel: 'valamis-course',
  typeName: 'courses',
};

export const WebContent: IContentType = {
  className: 'com.liferay.journal.model.journalarticle',
  bookmarkableName: undefined,
  isShareable: false,
  translationLabel: 'web-content',
  typeName: 'web contents',
};

export const Blog: IContentType = {
  className: 'com.liferay.blogs.model.blogsentry',
  bookmarkableName: undefined,
  isShareable: false,
  translationLabel: 'blog',
  typeName: 'blogs',
};

export const Wiki: IContentType = {
  className: 'com.liferay.wiki.model.wikipage',
  bookmarkableName: undefined,
  isShareable: false,
  translationLabel: 'wikipage',
  typeName: 'wikipage',
};

export const File: IContentType = {
  className: 'com.liferay.document.library.kernel.model.dlfileentry',
  isShareable: true,
  isDownloadable: true,
  isPreviewable: true,
  translationLabel: 'file',
  typeName: 'files',
};

export const Discussion: IContentType = {
  className: 'com.liferay.message.boards.model.mbmessage',
  bookmarkableName: undefined,
  isShareable: false,
  translationLabel: 'discussion',
  typeName: 'discussion',
};

export const Udemy: IContentType = {
  className: 'com.valamis.integrations.registry.model.udemycourse',
  bookmarkableName: undefined,
  isShareable: false,
  translationLabel: 'udemy-course',
  typeName: 'udemy-course',
};

export const Edx: IContentType = {
  className: 'com.valamis.edx.service.model.edxcourse',
  bookmarkableName: undefined,
  isShareable: false,
  translationLabel: 'edx-course',
  typeName: 'edx courses',
};

export const Harvard: IContentType = {
  className: 'com.valamis.integrations.registry.model.harvardarticle',
  bookmarkableName: undefined,
  isShareable: false,
  translationLabel: 'harvard-business-publishing',
  typeName: 'harvard-business-publishing',
};

export const LinkedIn: IContentType = {
  className: 'com.valamis.linkedin.integration.registry.model.course',
  bookmarkableName: undefined,
  isShareable: false,
  translationLabel: 'linkedin-course',
  typeName: 'linkedin courses',
};

export const GetAbstract: IContentType = {
  className: 'com.valamis.integrations.registry.model.getabstractsummary',
  bookmarkableName: undefined,
  isShareable: false,
  translationLabel: 'get-abstract-summary',
  typeName: 'get-abstract-summary',
};

export const Percipio: IContentType = {
  className: 'com.valamis.integrations.registry.model.percipiocourse',
  bookmarkableName: undefined,
  isShareable: false,
  translationLabel: 'percipio-course',
  typeName: 'percipio-course',
};

export const Go1: IContentType = {
  className: 'com.valamis.integrations.registry.model.go1entity',
  bookmarkableName: undefined,
  isShareable: false,
  translationLabel: 'go1-entity',
  typeName: 'go1-entity',
};

export const Ams: IContentType = {
  className: 'com.valamis.integrations.registry.model.amsentity',
  bookmarkableName: undefined,
  isShareable: false,
  translationLabel: 'ams-entity',
  typeName: 'ams-entity',
};

export const PluralSight: IContentType = {
  className: 'com.valamis.integrations.registry.model.pluralsightcourse',
  bookmarkableName: undefined,
  isShareable: false,
  translationLabel: 'pluralsight-course',
  typeName: 'pluralsight-course',
};

export const GoodHabitz: IContentType = {
  className: 'com.valamis.integrations.registry.model.goodhabitzcourse',
  bookmarkableName: undefined,
  isShareable: false,
  translationLabel: 'goodhabitz-course',
  typeName: 'goodhabitz-course',
};

export const ServicenowArticles: IContentType = {
  className: 'com.valamis.integrations.registry.model.serviceNowArticles',
  bookmarkableName: undefined,
  isShareable: false,
  translationLabel: 'servicenow-articles',
  typeName: 'servicenow-articles',
};

export const VivaLearning: IContentType = {
  className: 'com.valamis.integrations.registry.model.vivalearningcourse',
  bookmarkableName: undefined,
  isShareable: false,
  translationLabel: 'viva-learning-course',
  typeName: 'viva-learning-course',
};

export const UnknownType: IContentType = {
  className: 'unknown',
  bookmarkableName: undefined,
  isShareable: false,
  translationLabel: 'unknown',
  typeName: 'unknown',
};

const ContentTypeMap = {
  'com.arcusys.valamis.lesson.model.lesson': Lesson,
  'com.arcusys.valamis.learningpath.model.learningpath': LearningPath,
  'com.arcusys.valamis.assignment.model.assignment': Assignment,
  'com.liferay.calendar.model.calendarbooking': TrainingEvent,
  'com.valamis.course.model.course': Course,
  'com.liferay.document.library.kernel.model.dlfileentry': File,
  'com.liferay.journal.model.journalarticle': WebContent,
  'com.liferay.blogs.kernel.model.blogsentry': Blog,
  'com.liferay.blogs.model.blogsentry': Blog,
  'com.liferay.wiki.model.wikipage': Wiki,
  'com.liferay.message.boards.kernel.model.mbmessage': Discussion,
  'com.liferay.message.boards.model.mbmessage': Discussion,
  'com.valamis.integrations.registry.model.udemycourse': Udemy,
  'com.valamis.edx.service.model.edxcourse': Edx,
  'com.valamis.integrations.registry.model.harvardarticle': Harvard,
  'com.valamis.harvard.model.extendedentry': Harvard,
  'com.valamis.linkedin.integration.registry.model.course': LinkedIn,
  'com.valamis.integrations.registry.model.getabstractsummary': GetAbstract,
  'com.valamis.integrations.registry.model.percipiocourse': Percipio,
  'com.valamis.integrations.registry.model.go1entity': Go1,
  'com.valamis.integrations.registry.model.amsentity': Ams,
  'com.valamis.integrations.registry.model.pluralsightcourse': PluralSight,
  'com.valamis.integrations.registry.model.goodhabitzcourse': GoodHabitz,
  'com.valamis.integrations.registry.model.servicenowarticles': ServicenowArticles,
  'com.valamis.integrations.registry.model.vivalearningcourse': VivaLearning,
};

export const getContentType = (className: string): IContentType => {
  const contentType = ContentTypeMap[className.toLowerCase()];
  if (!contentType) {
    console.error('No content type found for className', className);
    return UnknownType;
  }
  return contentType;
};

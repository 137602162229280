import { AxiosInstance } from 'axios';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NSearchBarApp {
  export interface IOptionsStore {
    readonly apiClient: AxiosInstance;
  }
}
export interface ISearchBarAppOptions {
  readonly token: string;
  readonly userId: number;
  readonly userLocale: string;
  readonly keyword;
  readonly suggestionUrl: string;
  readonly minimumKeywordLength: number;
  readonly onEmptySearch: (() => void) | undefined;
  readonly onSearchDispatch: () => void;
  // eslint-disable-next-line
  readonly initParameters?: Record<string, any>;
  customTranslationsPrefix?: string;

  // TODO remove these at some point
  readonly externalListParentDomId: string;
  readonly openExternalListHook: () => void;
  readonly closeExternalListHook: () => void;
  readonly itemsOnPage?: number;
  readonly scopeId?: string;
  readonly portletTitle?: string;
  readonly placeholderText?: string;
}

export interface IAppStore {
  readonly setKeyword: (keyword: string) => void;
  readonly setKeywordWithoutSuggestions: (keyword: string) => void;
  readonly getSuggestions: string[];
  readonly getKeyword: string;
  readonly setScreenWidth: (number) => void;
  readonly getShowLocalSuggestionList: boolean;
  readonly getShowExternalSuggestionList: boolean;
  readonly getShowResetIcon: boolean;
  readonly getShowSearchIcon: boolean;
  readonly getExternalListDomId: string;
  readonly resetKeyword: () => void;
  readonly setSearchFieldFocusStatus: (show: boolean) => void;
  readonly selectKeyword: (keyword: string) => void;
  readonly isFieldFocusForced: boolean;
  readonly isFieldBlurForced: boolean;
  readonly runSearch: (keyword: string) => void;
  readonly runSearchByKeyword: () => void;
  readonly forceFocus: () => void;
  readonly forceBlur: () => void;
  readonly externalListDomId: string;
  readonly onEmptySearch: (() => void) | undefined;
}

export interface IOptionsSuggestionStore extends NSearchBarApp.IOptionsStore {
  readonly appOptions: ISearchBarAppOptions;
}

export enum States {
  Pending = 'Pending',
  Done = 'Done',
  Error = 'Error',
  Initial = 'Initial',
}

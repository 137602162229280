import React from 'react';
import { IConfigurationsStore, IErrorsStore, IResultStore } from './search-results';
import { IAppStore } from './search-bar';

export interface IToastManager {
  readonly add: (message: JSX.Element | string, options: IToastManagerOptions) => void;
}

interface IToastManagerOptions {
  readonly appearance: string;
  readonly autoDismiss: boolean;
  readonly autoDismissTimeout?: number;
}

export interface IAppContainerProps {
  children?: React.ReactNode;
  readonly toastManager?: IToastManager;
}

export interface IApiUrls {
  readonly results: string;
  readonly bookmarks: string;
  readonly suggestions: string;
  readonly localization: string;
  readonly vocabulary: (vocabularyId: number) => string;
  readonly getBookmark: (type: string, entityId: number | string) => string;
  readonly toggleBookmark: (id?: number | string) => string;
  readonly userLrsInfo: string;
  readonly getCategories: (vocabularyId: number) => string;
  readonly vocabularies: string;
  readonly courses: string;
  readonly getLiferayCategory: string;
}

export interface IApiClientOptions {
  token?: string;
  cookie?: string;
  host?: string;
  companyId?: string;
  clusterId?: string;
  locale?: string;
  timeout?: number;
}

export enum UIState {
  Hidden = 'Hidden',
  Pending = 'Pending',
  Done = 'Done',
  Error = 'Error',
  Initial = 'Initial',
}

export interface IStores {
  errorsStore: IErrorsStore;
  resultStore: IResultStore;
  configStore: IConfigurationsStore;
}

export interface ISearchBarStores {
  appStore: IAppStore;
}

export interface IAppScope {
  token?: string; // only for SSR
  // eslint-disable-next-line @typescript-eslint/ban-types
  initialStoreState?: object;
  // eslint-disable-next-line @typescript-eslint/ban-types
  initialI18nStore: object;
  initialLanguage?: string;
  i18path: string;
}

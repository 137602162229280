/* eslint-disable no-use-before-define */
import React, { FC, useEffect, useRef, useState } from 'react';
import { IconButton, Icons, ToggleSwitch } from '@valamis/ui-components';
import styled, { DefaultTheme } from 'styled-components';
import { useT } from '@valamis/i18n';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { IFilterSettingItem, IFilterSettings, IResultStore } from '../../../types';
import { Breakpoints } from '../../components/app/AppWrapper';

interface ITheme {
  theme?: DefaultTheme;
}

const StyledIconButton = styled(IconButton)<ITheme>`
  color: ${(props): string => props.theme.palette.black};
  top: 1px;

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const SettingsModal = styled.div<ITheme>`
  min-width: 180px;
  position: absolute;
  z-index: 1;
  left: -35px;
  top: 25px;
  height: auto;
  background: ${(props): string => props.theme.palette.white};
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.3);
  border-radius: ${(props): string => props.theme.forms.border.radius};
  padding: 16px;
  > input {
    margin-bottom: 8px;
  }
  > button {
    width: 100%;
    margin-top: 8px;
    height: 32px;
  }

  @media ${Breakpoints.mobileMaxWidth} {
    left: 0;
  }
`;

export const SettingsTitle = styled.h3<ITheme>`
  font-size: 10px;
  line-height: 16px;

  letter-spacing: 1px;
  text-transform: uppercase;

  color: ${(props): string => props.theme.palette.gray.dark};

  margin-top: 0px;
  margin-bottom: 8px;
`;

export const SettingWrapper = styled.div`
  display: flex;

  span.setting-label {
    margin-left: 10px;
    text-transform: none;
  }
`;

interface ISettingProps {
  item: IFilterSettingItem;
  // eslint-disable-next-line @typescript-eslint/ban-types
  activeFilters: object;
  onSettingChange: (setting: IFilterSettingItem, isToggled: boolean) => void;
}

const BooleanSetting: FC<ISettingProps> = (props: ISettingProps) => {
  const { item, onSettingChange, activeFilters } = props;

  const { t } = useT();
  const label = t(item.title);

  const onChange = (isToggled: boolean): void => {
    onSettingChange(item, isToggled);
  };

  const isSettingActive = activeFilters[item.parameter] === item.value;

  const getWidget = (widget: string): JSX.Element => {
    if (widget === 'switch') {
      return <ToggleSwitch onChange={onChange} checked={isSettingActive} toggleLabel={label} />;
    }
    return <></>;
  };

  return (
    <SettingWrapper>
      {getWidget(item.widget)}
      <span className="setting-label">{label}</span>
    </SettingWrapper>
  );
};

interface IFacetSettingsProps {
  settings: IFilterSettings;
  onSettingApplied?: () => void;
  resultStore: IResultStore;
}
export interface IOutProps {
  readonly settings: IFilterSettings;
  readonly onSettingApplied?: () => void;
}

const FacetSettings: FC<IFacetSettingsProps> = (props: IFacetSettingsProps): JSX.Element => {
  // eslint-disable-next-line react/destructuring-assignment
  const { settings, onSettingApplied, resultStore } = props;
  const modalRef = useRef<HTMLDivElement>();
  const settingsButtonRef = useRef<HTMLButtonElement>();
  const [showSettings, toggleShowSettings] = useState(false);

  const { t } = useT();

  const toggleSettings = (): void => toggleShowSettings((prevState: boolean) => !prevState);

  useEffect(() => {
    const handleClickOutside = (event): void => {
      const clickOutsideModal = modalRef.current && !modalRef.current.contains(event.target);
      const clickOnSettingsButton = settingsButtonRef.current && settingsButtonRef.current.contains(event.target);
      if (clickOutsideModal && !clickOnSettingsButton) {
        toggleSettings();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('scroll', handleClickOutside);
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('scroll', handleClickOutside);
    };
  }, [modalRef]);

  const onSettingChange = (setting: IFilterSettingItem, isToggled: boolean): void => {
    if (isToggled) {
      const filters = {
        [setting.parameter]: setting.value,
      };
      resultStore.setCurrentFilters(filters);
      onSettingApplied && onSettingApplied();
    } else {
      resultStore.removeFilter(setting.parameter);
    }

    const staticParams = {
      start: 0,
      q: resultStore.getMeta.getQueryTerms,
    };
    const params = { ...resultStore.getCurrentFilters, ...staticParams };

    resultStore.resetStore();
    // eslint-disable-next-line
    resultStore.fetchResults(params);
  };

  const renderSettings = (): JSX.Element | null => {
    if (showSettings) {
      return (
        // @ts-ignore
        <SettingsModal ref={modalRef}>
          <SettingsTitle>{t(settings.title)}</SettingsTitle>
          {settings.items.map((item) => (
            <BooleanSetting
              key={item.parameter}
              item={item}
              activeFilters={resultStore.getCurrentFilters}
              onSettingChange={onSettingChange}
            />
          ))}
        </SettingsModal>
      );
    }
    return null;
  };

  return (
    <>
      <StyledIconButton
        className="filter-settings-button"
        data-marker="toggle-filters-settings"
        onClick={toggleSettings}
        aria-label={t(settings.title)}
        // @ts-ignore, TODO fix typescript ignore
        ref={settingsButtonRef}
      >
        <Icons.Settings />
      </StyledIconButton>
      {renderSettings()}
    </>
  );
};

FacetSettings.defaultProps = {
  onSettingApplied: undefined,
};

export default compose<IFacetSettingsProps, IOutProps>(inject('resultStore'), observer)(FacetSettings);

import { v4 as uuidv4 } from 'uuid';
import { IXAPIStatement } from './types';
import { createExtensionIRI } from './util';
import { stripHtml } from '../index';
import { ISearchResult } from '../../types';

export enum Verb {
  previewed = 'previewed',
  downloaded = 'downloaded',
}

export const buildStatement = (item: ISearchResult, verb: Verb): IXAPIStatement => ({
  id: uuidv4(),
  verb,
  item: {
    link: encodeURI(item.link),
    title: stripHtml(item.title),
    extensions: {
      [createExtensionIRI('file-entry-id')]: item.fileEntryId,
      [createExtensionIRI('folder-id')]: item.folderId,
      [createExtensionIRI('repository-id')]: item.repositoryId,
    },
  },
});

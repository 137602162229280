/* eslint-disable no-use-before-define */
import React, { FunctionComponent } from 'react';
import { Col, Hidden } from 'react-grid-system';
import styled from 'styled-components';
import { compose } from 'recompose';
import { inject, observer } from 'mobx-react';
import { PrimaryButton } from '@valamis/ui-components';
import { useT } from '@valamis/i18n';
import {IFacet, IContainerProps, IOutContainerProps, UIState} from '../../../types';
import FacetFilter from './Facet';
import { SkipToLink } from '../../../utilities';
import { FILTER_ITEMS_ON_PAGE, FIRST_FILTER_ID, RESULT_LIST_ID } from '../../../consts';

const FilterCol = styled(Col)`
  margin-top: 4px;

  &.mobile-filter {
    font-size: 16px;
    padding-bottom: 50px;

    .facet-filter > div {
      font-size: 16px;
    }

    & > div:not(:last-child)::after {
      content: ' ';
      display: block;
      position: absolute;
      height: 1px;
      background: ${(props): string => props.theme.palette.veryLightGray};
      width: calc(100% - 30px);
      margin-top: 10px;
      left: 15px;
    }

    label,
    div[role='radio' i] {
      span {
        font-size: 16px;
      }
      &:not(:first-child):not(:last-child) {
        margin-top: 8px;
        margin-bottom: 8px;
      }
    }

    label {
      div,
      input {
        font-size: 16px;
        width: 24px;
        height: 24px;
      }
    }

    div[role='radiogroup' i] {
      span {
        width: 24px;
        height: 24px;
        margin-right: 16px;

        &:before {
          margin-left: 5px;
          margin-top: 5px;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
`;

const Footer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  border-top: 1px solid ${(props): string => props.theme.palette.veryLightGray};
  background-color: ${(props): string => props.theme.palette.white};
  padding: 16px 0;

  button {
    width: 100%;
  }
`;

const FiltersContainer: FunctionComponent<IContainerProps> = (props: IContainerProps): JSX.Element => {
  const { t } = useT();
  const { resultStore, onFilterChange, facets } = props;
  const { showMobileFilters, getCurrentFilters } = resultStore;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const showResults = (): void => {
    onFilterChange();
    showMobileFilters && resultStore.toggleMobileView(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const changeFilter = (entity: Record<string, any>, field: string, isMulti?: boolean): void => {
    let curFilter = getCurrentFilters[field] || [];
    let filter;
    if (isMulti) {
      if (typeof curFilter === 'string') curFilter = curFilter.split(',');
      filter = new Set(curFilter);
      const entityId = entity.id.toString();

      if (filter.has(entityId)) filter.delete(entityId);
      else filter.add(entityId);
    } else {
      filter = new Set();
      filter.add(entity.id.toString());
    }

    resultStore.setCurrentFilters({ [field]: [...filter] });
    showResults();
  };

  // const vocabularyFilters: JSX.Element[] = useMemo(() => getVocabulariesJSX(vocabulariesStore.getVocabularies), [vocabulariesStore.getVocabularies]);

  const facetsFilters = facets
    .sort((a, b) => {
      if (a.client_params.order_number < b.client_params.order_number) return -1;
      return 1;
    })
    .map((facet: IFacet, index: number): JSX.Element | null =>
      !facet.client_params.hide ? (
        <FacetFilter
          id={index === 0 ? FIRST_FILTER_ID : null}
          key={facet.field_name}
          facet={facet}
          onValueSelect={changeFilter}
          visibleCount={showMobileFilters ? FILTER_ITEMS_ON_PAGE : facet.client_params.value_count_at_start}
          filter={getCurrentFilters[facet.param_name]}
        />
      ) : null
    );

  const backToResultsLink = <SkipToLink href={`#${RESULT_LIST_ID}`}>{t('Go back to search results')}</SkipToLink>;

  return resultStore.getLoadingState === UIState.Done ? (
    <>
      <Hidden xs sm>
        <FilterCol md={4} lg={4} xl={4}>
          {facetsFilters}
          {backToResultsLink}
        </FilterCol>
      </Hidden>
      <Hidden md lg xl>
        {showMobileFilters && (
          <>
            <FilterCol className="mobile-filter">{facetsFilters}</FilterCol>

            <Footer>
              <Col>
                <PrimaryButton data-marker="show-results-button" onClick={(): void => showResults()}>
                  {t('Show Results')}
                </PrimaryButton>
              </Col>
            </Footer>
          </>
        )}
      </Hidden>
    </>
  ) : (
    <></>
  );
};

export default compose<IContainerProps, IOutContainerProps>(inject('resultStore'), observer)(FiltersContainer);

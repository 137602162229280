/* eslint-disable no-use-before-define */
import React from 'react';
import { useT } from '@valamis/i18n';
import { Icons } from '@valamis/ui-components';
import styled from 'styled-components';

const Wrapper = styled.div`
  line-height: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    font-size: 24px;
    font-weight: 700;
    margin: 0;
  }
`;

const IconWrapper = styled.div`
  margin-top: 100px;

  svg {
    height: 50px;
    width: 50px;
    fill: ${(props): string => props.theme.palette.darkGray};
  }
`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Empty = (_props): JSX.Element => {
  const { t } = useT();

  return (
    <Wrapper>
      <IconWrapper>
        <Icons.Search />
      </IconWrapper>
      <h2 aria-hidden="true">{t('No results found')}</h2>
    </Wrapper>
  );
};

export default Empty;

/* eslint-disable no-use-before-define,camelcase */
import React, { FC } from 'react';
import { useT } from '@valamis/i18n';
import { Chip, Text } from '@valamis/ui-components';
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import {IFacet, IFacetValue, IFilterProps, UIState} from "../../../types";
import {IFacetValueOpts} from "../../stores";

const ChipsWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 783px) {
    white-space: nowrap;
    overflow-x: scroll;
  }

  & > div {
    margin: 0 8px 8px 0;
    line-height: 16px;
  }

  & > button {
    margin-bottom: 8px;
    font-weight: normal;
    height: auto;
  }
`;

interface IChipsProps {
  readonly uiState: string;
  readonly currentFilters: IFilterProps;
  readonly facets: IFacet[];
  readonly onRemoveFilter: (value: Record<string, unknown>) => void;
  readonly onResetFilters: () => void;
}

const FilterChips: FC<IChipsProps> = (props: IChipsProps): JSX.Element => {
  const { uiState, currentFilters, facets, onRemoveFilter, onResetFilters } = props;
  const { t } = useT();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { q, start, ...filters } = currentFilters;
  let showChips = false;

  const removeFilter = (field: string, value: string): void => {
    const filter = new Set([].concat(filters[field]));
    // @ts-ignore
    filter.delete(value);
    onRemoveFilter({ [field]: [...filter] });
  };

  const chips = Object.keys(filters).map((key) => {
    const values: string | string[] = filters[key];
    const facet: IFacet | undefined = facets.find((item) => item.param_name === key);
    const facetValues: IFacetValue[] = facet?.values.filter((fValue: IFacetValue) => {
      if (Array.isArray(values)) return values.includes(fValue.term);
      return values === fValue.term;
    });

    if (!facet || facet.client_params.hide || !values.length) {
      return null;
    }

    showChips = true;

    const { title, localize_title, localize_values, localizable_postfix } = facet.client_params;

    const facetTitleLabel: string = title ?? facet.field_name;
    const facetTitle: string = localize_title ? t(facetTitleLabel) : facetTitleLabel;

    const getNameOpts: IFacetValueOpts = {
      showFrequency: false,
      translate: t,
      localizeValues: localize_values,
      localizablePostfix: localizable_postfix,
    };
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    const fullLabel = (fValue: IFacetValue): string => `${t('filter')} ${facetTitle}: ${fValue.getName(getNameOpts)}`;
    return facetValues.map((fValue: IFacetValue) => (
      <Chip
        key={uuidv4()}
        chipLabel={fullLabel(fValue)}
        deleteLabel={t('Remove filter')}
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        label={`${facetTitle}: ${fValue.getName(getNameOpts)}`}
        onDelete={(): void => removeFilter(key, fValue.term)}
      />
    ));
  });

  return showChips ? (
    <ChipsWrapper>
      {chips}
      <Text onClick={onResetFilters}>{t('Clear filters')}</Text>
    </ChipsWrapper>
  ) : (
    <></>
  );
};

export default FilterChips;

export const getUrlParameters = (): Record<string, any> => {
  const params: Record<string, any> = {};
  try {
    if (window) {
      const queryParams = new URLSearchParams(window.location.search);
      // eslint-disable-next-line no-return-assign
      queryParams.forEach(
        (value: string, key: string) =>
          (params[key] = queryParams.getAll(key).length > 1 ? queryParams.getAll(key) : value)
      );
    }
  } catch (e) {
    // Ignore 'window is not defined'
  }

  return params;
};

export const getUrlParameter = (parameterName: string): string | undefined => getUrlParameters()[parameterName];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const setUrlParams = (params: Record<string, any>): void => {
  try {
    const stringified = Object.keys(params).reduce((acc, key, index) => {
      let current = '';
      const paramValue = params[key];
      if (Array.isArray(paramValue)) {
        paramValue.forEach((value, idx) => {
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          current = idx === 0 ? `${key}=${value}` : `${current}&${key}=${value}`;
        });
      } else {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        current = `${key}=${paramValue}`;
      }
      return index === 0 ? `?${current}` : `${acc}&${current}`;
    }, '');
    if (window && stringified?.length > 0) {
      window.history.pushState(null, '', `${stringified}`);
    }
  } catch (e) {
    console.warn('Failed to set window url parameters');
  }
};

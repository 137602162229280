import { IApiUrls } from '../types';

const ApiUrls: IApiUrls = {
  results: '/v-api/search',
  bookmarks: '/delegate/valamis-bookmarks',
  suggestions: '/v-api/search/suggest',
  vocabulary: (vocabularyId): string => `/delegate/liferay/vocabularies/${vocabularyId}/categories/`,
  getBookmark: (type: string, id: string | number): string =>
    `/delegate/valamis-bookmarks/bookmarks?entityType=${type}&entityId=${id}`,
  toggleBookmark: (id?: string | number): string => `/delegate/valamis-bookmarks/bookmarks/${id || ''}`,
  localization: `/delegate/valamis-localization/groups`,
  userLrsInfo: '/delegate/valamis-mobile/user/',
  getCategories: (vocabularyId: number): string => `/delegate/liferay/vocabularies/${vocabularyId}/categories`,
  vocabularies: '/o/valamis-liferay-asset-vocabularies/com.liferay.portal.kernel.model.User/vocabularies',
  courses: '/api/jsonws/group/get-group/group-id',
  getLiferayCategory: '/api/jsonws/assetcategory/fetch-category/category-id',
};

export default ApiUrls;

import { AxiosInstance } from 'axios';
import { ILRSClient, ILRSConfiguration, IXAPIActor } from './types';
import { TincanWrapper } from './tincan-wrapper';
import ApiUrls from '../../api/apiUrls';

interface ILRSInfo {
  readonly lrsConfig: ILRSConfiguration;
  readonly actor: IXAPIActor;
}

const getLRSConfig = async (apiClient: AxiosInstance): Promise<ILRSInfo> => {
  try {
    const resp = await apiClient.get(ApiUrls.userLrsInfo);
    const { agent, endpointInfo } = resp.data;
    return {
      lrsConfig: {
        auth: endpointInfo.auth,
        authType: endpointInfo.authType,
        endpoint: endpointInfo.endpoint,
        isInternal: endpointInfo.internal,
        lrsVersion: endpointInfo.version,
      },
      actor: {
        account: {
          homePage: agent.account.homePage,
          name: agent.account.name,
        },
        name: agent.name,
      },
    };
  } catch (e) {
    console.error('Unable to get LRS information');
    throw e;
  }
};

let lrsInfo: ILRSInfo | undefined;

export const getLRSClient = async (apiClient: AxiosInstance): Promise<ILRSClient> => {
  lrsInfo = lrsInfo || (await getLRSConfig(apiClient));
  return new TincanWrapper(lrsInfo.lrsConfig, lrsInfo.actor);
};

import { Provider } from 'mobx-react';
import React from 'react';
import Application from 'valamis.application';
import { configure } from 'mobx';
import {IApiClientOptions, IAppOptions, IAppScope} from 'types';
import { ClientCssContext } from '@valamis/ui-components';
import { SEARCH_RESULTS_APP } from '../../../consts';
import SearchResultsApp from '../../../search-results/App';
import { getStores } from '../../init-stores/client';

class SearchResults extends Application {
  options: IAppOptions;

  i18nNamespace: string;

  constructor(instanceId: string, appOptions: IAppOptions, appScope?: IAppScope) {
    super(SEARCH_RESULTS_APP.SHORTNAME, instanceId, SEARCH_RESULTS_APP.DOM_ROOT_PREFIX, appScope);
    this.options = appOptions || {};
    this.options.customTranslationsPrefix = SEARCH_RESULTS_APP.I18N.CUSTOM_TRANSLATIONS_PREFIX;
    this.i18nNamespace = SEARCH_RESULTS_APP.I18N.NAMESPACE;
  }

  start(): void {
    super.start();

    configure({ enforceActions: 'observed' });

    if (module.hot) {
      module.hot.accept();
    }
  }

  onStart(): void {
    this.render();
  }

  render(): void {
    const apiConfig: IApiClientOptions = {
      locale: this.options.userLocale,
      token: this.options.token
    };
    const stores = getStores(this.options, apiConfig, this.appScope);

    this.options?.onPrerender &&
      this.options.onPrerender(() => {
        super.render(
          <ClientCssContext>
            <Provider
              configurationsStore={stores.configStore}
              resultStore={stores.resultStore}
              errorsStore={stores.errorsStore}
            >
              <SearchResultsApp />
            </Provider>
          </ClientCssContext>
        );
      });
  }

  // eslint-disable-next-line class-methods-use-this
  onDestroy(): void {
    // remove events handlers if needed
  }

  destroy(): void {
    super.destroy();
  }
}

export default SearchResults;

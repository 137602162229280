import axios, { AxiosInstance } from 'axios';
import { HEADERS } from '../consts';
import { IApiClientOptions } from '../types';

export default function createApiClient(config: IApiClientOptions): AxiosInstance {
  const headers = {};
  headers[HEADERS.X_CSRF_TOKEN] = config.token;

  if (!!config.cookie) {
    // @ts-ignore
    headers[HEADERS.COOKIE] = config.cookie;
  }

  if (!!config.host) {
    // @ts-ignore
    headers[HEADERS.HOST] = config.host;
  }

  if (!!config.clusterId) {
    headers[HEADERS.X_CLUSTER_ID] = config.clusterId;
  }

  if (!!config?.locale) {
    headers['Accept-Language'] = config.locale.replaceAll('_', '-');
  }

  return axios.create({
    timeout: config?.timeout || 20000,
    headers,
  });
}

/* eslint-disable camelcase */
/* eslint no-use-before-define: 0 */
import { IMiddlewareHandler, Instance } from 'mobx-state-tree';
import { AxiosInstance } from 'axios';
import React, { SyntheticEvent } from 'react';
import {
  SearchResult,
  Paging,
  Facet,
  FacetValue,
  Meta,
  MetaData,
  IPage,
  IFacetValueOpts,
} from '../search-results/stores';
import { VocabularyModel } from '../search-results/stores/models';
import { ILRSClient, IXAPIStatement } from '../utilities/lrs/types';
import { IContentType } from './content-type';
import { IApiUrls, UIState } from './common';

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace NSearchResultsApp {
  export interface IOptionsStore {
    readonly ApiUrls: IApiUrls;
    readonly apiClient: AxiosInstance;
    readonly errorsStore: IErrorsStore;
  }
}
export interface IAppOptions {
  readonly token: string;
  readonly userId: number;
  readonly itemsOnPage: number;
  readonly userLocale: string;
  readonly searchUrl: string;
  readonly scopeId: string;
  readonly portletTitle: string;
  readonly isBookmarksEnabled: boolean;
  readonly isMessagingToolEnabled: boolean;
  readonly renderUrl: string;
  readonly onNavigateBack?: (e: SyntheticEvent) => void;
  readonly onPrerender?: (renderCallback: () => void) => void;
}

export interface IOptionsResultStore extends NSearchResultsApp.IOptionsStore {
  readonly ApiUrls: IApiUrls;
  readonly apiClient: AxiosInstance;
  readonly errorsStore: IErrorsStore;
}

export interface ISharingEntity {
  readonly course: ICourseData;
  readonly title: string;
  readonly description: string;
  readonly imageSrc?: string;
  readonly link: string;
  readonly type: string;
}

export interface IBookmarkEntity {
  readonly entityType: string;
  readonly entityId: number;
  readonly title: string;
}

export interface IErrorsStore {
  readonly hasError: boolean;
  readonly pullError: () => string;
  readonly setError: (error: string) => void;
  readonly errorHandlerActionDecorator: IMiddlewareHandler;
}

export interface IConfigurationsStore {
  readonly userId: number;
  readonly itemsOnPage: number;
  readonly userLocale: string;
  readonly portletTitle: string;
  readonly isBookmarksEnabled: boolean;
  readonly isMessagingToolEnabled: boolean;
  readonly onNavigateBack?: (e: SyntheticEvent) => void;
}

export interface IContainerProps {
  readonly resultStore: IResultStore;
  readonly facets: IFacet[];
  readonly onFilterChange: () => void;
}

export interface IOutContainerProps {
  readonly onFilterChange: () => void;
  readonly facets: IFacet[];
}

export interface IVocabulary extends Instance<typeof VocabularyModel> {
  readonly id: number;
  readonly title: string;
  readonly categories: ICategory[];

  readonly addCategory: (newCategory: ICategory) => void;
}

export interface ICategory {
  readonly id: number;
  readonly title: string;
  readonly vocabularyId: number;
}

export interface ICourseData {
  readonly id: number;
  readonly name: string;
}

export interface IItemPermissions {
  readonly download?: boolean;
}

export interface IFilterProps {
  readonly q?: string;
  readonly start?: number;
  readonly type?: string | string[];
  readonly categoryId?: string | string[];
  readonly duration?: string | string[];
  readonly scope?: string | string[];
  readonly time?: string;
  readonly renderUrl?: string;
  readonly startTime?: string;
}

export type IFilterOption = {
  id: string | number;
  title: string;
  value: boolean;
  frequency: number;
  children?: React.ReactNode;
};

export interface IPaging extends Instance<typeof Paging> {
  readonly pages: IPage[];
  readonly currentPage: number;
}
export interface IFacet extends Instance<typeof Facet> {
  setValues: (values: IFacetValue[]) => void;
}

export interface IFilterSettingItem {
  readonly type: string;
  readonly title: string;
  readonly parameter: string;
  readonly widget: string;
  readonly value: string;
}
export interface IFilterSettings {
  readonly title: string;
  readonly items: IFilterSettingItem[];
}

// @ts-ignore, TODO Remove ts-ignore
export interface IFacetValue extends Instance<typeof FacetValue> {
  readonly name: string;
  readonly term: string;
  readonly frequency: number;
  readonly settings?: IFilterSettings;
  getName(opts: IFacetValueOpts): string;
  setFreq(freq: number): void;
}

export interface IMeta extends Instance<typeof Meta> {
  readonly executionTime: string;
  readonly totalHits: number;
  readonly queryTerms: string;
  readonly originalQueryTerms?: string;
  readonly start: number;
  readonly totalPages: number;
  readonly querySuggestions?: string[];
}

export interface IMetadata extends Instance<typeof MetaData> {
  readonly size: string | undefined;
  readonly format: string | undefined;
  readonly openNewTab: string | undefined;
}

export interface ISearchResult extends Instance<typeof SearchResult> {
  readonly date: string;
  readonly categories?: ICategory[];
  readonly ratingScore: string;
  readonly description: string;
  readonly viewsCount: string;
  readonly author: string;
  readonly timestamp: string;
  readonly imageSrc?: string;
  readonly title: string;
  readonly link: string;
  readonly shareableLink: string;
  readonly redirect: string;
  readonly previewUrl: string;
  readonly strippedtitle: string;
  readonly ratings: string;
  // eslint-disable-next-line camelcase
  readonly title_raw: string;
  readonly course?: ICourseData;
  readonly id: string;
  readonly previewCount: number;
  readonly repositoryId?: string;
  readonly folderId?: string;
  readonly fileEntryId?: string;
  readonly bookmarkId: number;
  readonly metadata: IMetadata;
  readonly duration: string;
  readonly permissions: IItemPermissions;
  readonly className: string;
  readonly urn: string;
  readonly getContentType: IContentType;
}

export interface IResultStore {
  readonly fetchResults: (params: Record<string, unknown>, init?: boolean) => Promise<void>;
  readonly resetStore: () => void;
  readonly getResults: ISearchResult[];
  readonly getLoadingState: UIState;
  readonly getPaging: IPaging;
  readonly getFacets: IFacet[];
  readonly getMeta: IMeta;
  readonly setCurrentPage: (page: number) => void;
  readonly setCurrentFilters: (filters: Record<string, unknown>) => void;
  readonly removeFilter: (filterName: string) => void;
  readonly toggleMobileView: (flag: boolean) => void;
  readonly getCurrentFilters: IFilterProps;
  readonly clearFilters: () => void;
  readonly showMobileFilters: boolean;
  readonly sendXAPIStatement: (statement: IXAPIStatement) => void;
  readonly userLocale: string;
}

export type shareMenuItems = {
  id: number;
  title: string;
  onClick: (entity: ISearchResult) => void;
};
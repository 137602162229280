/* eslint-disable no-use-before-define */
import React from 'react';
import { inject, observer } from 'mobx-react';
import { Col, Hidden } from 'react-grid-system';
import { compose } from 'recompose';
import { useT } from '@valamis/i18n';
import styled from 'styled-components';
import { IconButton, Icons } from '@valamis/ui-components';
import { IConfigurationsStore, IResultStore, UIState } from '../../../types';
import { IPage, TimeScope } from '../../stores';
import { SkipToLink } from '../../../utilities';
import { FIRST_FILTER_ID } from '../../../consts';

const Title = styled.h1`
  margin: 0;
  line-height: 28px;
  font-size: 24px;
  font-weight: 700;
`;

const Show = styled.div`
  color: ${(props): string => props.theme.base.font.color};
  margin-top: 12px;
  font-weight: bold;

  &.desktop {
    display: flex;
    margin: 4px 20px 0 20px;
  }
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const IconWrapper = styled.div`
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  & > button {
    &.applied-filters::after {
      top: 0;
      right: 0;
      content: ' ';
      display: block;
      position: absolute;
      background: ${(props): string => props.theme.palette.primary};
      width: 8px;
      height: 8px;
      border-radius: 50%;
      box-shadow: -2px 1px 0px ${(props): string => props.theme.palette.white};
    }
  }
`;

const CancelMobileFilterWrapper = styled.div`
  width: 100%;
  background: linear-gradient(180deg, #ffffff80 0, #ffffff00 100%), #f0f1f2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  position: fixed;
  height: 56px;
  top: 0;
  z-index: 10000;

  span {
    margin-left: 30px;
    font-weight: 700;
    font-size: 14px;
  }
`;

const BackButtonWrapper = styled.div`
  position: absolute;
  left: -28px;
`;

const BackButton = styled(IconButton)`
  color: ${(props): string => props.theme.palette.primary};
  margin: 0;
  font-size: 14px;
  padding: 6px 8px;
  width: auto;

  svg {
    position: static;
  }
`;

interface IProps {
  readonly resultStore: IResultStore;
  readonly configurationsStore: IConfigurationsStore;
}

const Header = (props: IProps): JSX.Element => {
  const { configurationsStore, resultStore } = props;
  const { t } = useT();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isFiltersApplied = (object: Record<string, any>): boolean => {
    Object.keys(object).forEach(
      (key: string) =>
        // eslint-disable-next-line no-prototype-builtins
        object.hasOwnProperty(key) &&
        object[key].length > 0 &&
        !['q', 'start', 'rows'].includes(key) &&
        object[key] !== TimeScope[0].id &&
        !key.includes('p_p')
    );
    return false;
  };

  const getShowTo = (activePage: IPage, pageCount: number, searchResultCount: number, itemsOnPage = 25): number =>
    activePage.number === pageCount ? searchResultCount : activePage.start + itemsOnPage;

  const meta = resultStore.getMeta;
  const paging = resultStore.getPaging;
  const total = meta.getTotalHints;
  const totalPages = meta.getPagesCount;
  const page = paging.getActive;
  const showTo = total ? getShowTo(page, totalPages, total, configurationsStore.itemsOnPage) : 0;
  const buttonALabel = resultStore.showMobileFilters ? t('Close filters') : t('Open filters');
  const cancelFiltersAriaLabel = t('Close filters');
  const actualFilters = { ...{}, ...resultStore.getCurrentFilters };
  const iconClassname = isFiltersApplied(actualFilters) ? 'applied-filters' : '';

  const toggleFilters = (): void => {
    resultStore.toggleMobileView(!resultStore.showMobileFilters);
  };

  const renderTitle = (): JSX.Element => <Title>{t(configurationsStore.portletTitle || 'Search results')}</Title>;

  const resultsLabel = total > 0 ? t('Found {total} search results', { total }) : t('No results found');

  const renderResultCounts = (isDesktop?: boolean): JSX.Element => {
    if (resultStore.getLoadingState === UIState.Done) {
      return (
        <>
          <Show
            data-marker="pages-info"
            className={isDesktop ? 'desktop' : ''}
            role="status"
            aria-live="polite"
            aria-label={resultsLabel}
          >
            {total > 0 && (
              <span aria-hidden="true">
                {t('PagesInfoLabel', {
                  start: parseInt(page.start) + 1,
                  end: showTo,
                  total,
                })}
              </span>
            )}
          </Show>
        </>
      );
    }

    return <></>;
  };

  const renderToggleFilterButton = (): JSX.Element => (
    <IconWrapper>
      <IconButton
        className={iconClassname}
        data-marker="open-filters-button"
        onClick={toggleFilters}
        aria-label={buttonALabel}
      >
        <Icons.Filtering />
      </IconButton>
    </IconWrapper>
  );

  const renderBackButton = (): JSX.Element => (
    <BackButtonWrapper>
      <BackButton onClick={configurationsStore.onNavigateBack} aria-label={t('Back to previous page')}>
        <Icons.ArrowBack />
      </BackButton>
    </BackButtonWrapper>
  );

  const renderSkipToFilters = (): JSX.Element => {
    if (resultStore.getLoadingState === UIState.Done) {
      return <SkipToLink href={`#${FIRST_FILTER_ID}`}>{t('Skip to filters')}</SkipToLink>;
    }
    return <></>;
  };

  if (!resultStore.showMobileFilters) {
    return (
      <>
        <Col xs={10} sm={10} md={12} lg={12} xl={12}>
          <FlexRow>
            {configurationsStore.onNavigateBack && renderBackButton()}
            {renderTitle()}
            <Hidden xs sm>
              {renderResultCounts(true)}
              {renderSkipToFilters()}
            </Hidden>
          </FlexRow>
        </Col>

        {/*
          // @ts-ignore: */}
        <Hidden md lg xl xxl>
          <Col xs={2} sm={2}>
            {renderToggleFilterButton()}
          </Col>
        </Hidden>

        {/*
          // @ts-ignore: */}
        <Hidden md lg xl xxl>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            {renderResultCounts(false)}
          </Col>
        </Hidden>
      </>
    );
  }

  return (
    <CancelMobileFilterWrapper>
      <IconButton
        className={iconClassname}
        data-marker="cancel-filters-button"
        onClick={toggleFilters}
        aria-label={cancelFiltersAriaLabel}
        tabIndex={0}
      >
        <Icons.Remove />
      </IconButton>
      <span>{t('Filters')}</span>
    </CancelMobileFilterWrapper>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default compose<IProps, {}>(inject('configurationsStore', 'resultStore'), observer)(Header);

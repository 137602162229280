/* eslint-disable no-use-before-define */
import React, { FC, useEffect, useMemo } from 'react';
import { inject, observer } from 'mobx-react';
import { Col, Container, Hidden, Row, ScreenClassProvider } from 'react-grid-system';
import { compose } from 'recompose';
import { Centerer, Loader } from '@valamis/ui-components';
import styled from 'styled-components';
import { IAppOptions, IResultStore, UIState } from '../../../types';
import AppContainer from './AppContainer';
import FiltersContainer from '../../views/filters/FiltersContainer';
import Header from './Header';
import ErrorPage from '../../views/resultList/ErrorPage';
import ResultListContainer from '../../views/resultList/ResultsContainer';
import FilterChips from '../../views/filters/Chips';
import { KEYWORD_PARAMETER_KEY, RESULT_ITEM_ID_PARAM, SEARCH_OPEN_PARAM } from '../../../consts';
import { scrollIntoView } from '../../../utilities';
import { eventBus, SearchEvents } from '../../../utilities/event-bus';
import { getUrlParameters } from '../../../utilities/parameters';

interface IApp {
  readonly resultStore: IResultStore;
  readonly configurationsStore: IAppOptions;
}

const LoaderWrapper = styled.div`
  margin-top: 90px;

  svg {
    width: 50px;
    height: 50px;

    circle {
      r: 28;
      stroke-width: 8px;
    }
  }
`;

const App: FC<IApp> = (props: IApp): JSX.Element => {
  const { resultStore, configurationsStore } = props;
  const uiState = resultStore.getLoadingState;
  const meta = resultStore.getMeta;

  let view: JSX.Element;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const initResults = (params: Record<string, any>): void => {
    const resultItemId: string = params[RESULT_ITEM_ID_PARAM];
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    resultStore.fetchResults(params, true).then((_) => {
      if (resultItemId?.length > 0) scrollIntoView(resultItemId, 65);
    });
  };

  const startUpParams = getUrlParameters();
  if (
    (uiState === UIState.Hidden || uiState === UIState.Initial) &&
    startUpParams[SEARCH_OPEN_PARAM] &&
    startUpParams[KEYWORD_PARAMETER_KEY]
  ) {
    initResults(startUpParams);
  }

  useEffect(() => {
    // eslint-disable-next-line
    eventBus.on(SearchEvents.runSearch, (parameters: Record<string, any>) => {
      const newParams = { ...getUrlParameters(), ...parameters };
      if (newParams[SEARCH_OPEN_PARAM] && newParams[KEYWORD_PARAMETER_KEY]) {
        initResults(newParams);
      } else {
        console.warn(
          `Not showing results, because '${SEARCH_OPEN_PARAM}' or '${KEYWORD_PARAMETER_KEY}' parameters  not set.`
        );
      }
    });
  }, []);

  const onFiltersChange = (): void => {
    const staticParams = {
      start: 0,
      q: resultStore.getMeta.getQueryTerms,
    };
    const params = { ...resultStore.getCurrentFilters, ...staticParams };

    resultStore.resetStore();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    resultStore.fetchResults(params);
  };

  const removeFilter = (value: Record<string, unknown>): void => {
    resultStore.setCurrentFilters(value);
    onFiltersChange();
  };

  const resetFilters = (): void => {
    let searchUrl = '';
    const params = {
      q: meta.getQueryTerms,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } as Record<string, any>;

    try {
      searchUrl = new URL(configurationsStore.searchUrl).search;
    } catch (_) {
      console.error('Not defined search URL.');
    }
    const urlParams = new URLSearchParams(searchUrl);
    urlParams.forEach(
      (value: string, key: string) => (params[key] = urlParams.getAll(key).length > 1 ? urlParams.getAll(key) : value)
    );

    resultStore.clearFilters();
    resultStore.resetStore();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    resultStore.fetchResults(params);
  };

  const filterChips: JSX.Element = useMemo(
    () => (
      <FilterChips
        uiState={resultStore.getLoadingState}
        currentFilters={resultStore.getCurrentFilters}
        facets={resultStore.getFacets}
        onRemoveFilter={removeFilter}
        onResetFilters={resetFilters}
      />
    ),
    [resultStore.getCurrentFilters]
  );

  switch (uiState) {
    case UIState.Hidden:
      return <></>;
    case UIState.Done:
      view = <ResultListContainer />;
      break;
    case UIState.Initial:
      view = <></>;
      break;
    case UIState.Pending:
      view = (
        <LoaderWrapper>
          <Centerer>
            <Loader pastDelay />
          </Centerer>
        </LoaderWrapper>
      );
      break;
    case UIState.Error:
    default:
      view = <ErrorPage />;
      break;
  }

  return (
    <AppContainer>
      {/*
      // @ts-ignore: useOwnWidth works but it is missing in props of ScreenClassProvider in external library */}
      <ScreenClassProvider useOwnWidth>
        <Container fluid={false}>
          <Row>
            <Header />
          </Row>
          <Row>
            <Hidden xs sm>
              <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                {filterChips}
                {view}
              </Col>
            </Hidden>
            <Hidden md lg xl>
              {!resultStore.showMobileFilters && (
                <Col>
                  {filterChips}
                  {view}
                </Col>
              )}
            </Hidden>
            {uiState !== UIState.Initial && uiState !== UIState.Error && (
              <FiltersContainer onFilterChange={onFiltersChange} facets={resultStore.getFacets} />
            )}
          </Row>
        </Container>
      </ScreenClassProvider>
    </AppContainer>
  );
};

// eslint-disable-next-line @typescript-eslint/ban-types
export default compose<IApp, {}>(inject('resultStore', 'configurationsStore'), observer)(App);

/* eslint no-use-before-define: 0 */
import React, { FC } from 'react';
import { withToastManager } from '@valamis/ui-components';
import { TFunction, useT } from '@valamis/i18n';
import { compose } from 'recompose';
import { ISearchResult, IToastManager, shareMenuItems } from '../../../types';
import {
  copyToClipboard,
  getDownloadLessonUrl,
  getFileDownloadLink,
  getResultItemDomId,
  getSharingEntity,
} from '../../../utilities';
import { buildStatement, Verb } from '../../../utilities/lrs/statements';
import { eventBus, MessagingEvents } from '../../../utilities/event-bus';
import {IXAPIStatement} from "../../../utilities/lrs/types";
import {getContentType, Ams, Lesson, File} from "../../../types/content-type";
import { ResultItem } from './ResultItem';

interface IResultListProps {
  results: ISearchResult[];
  toastManager: IToastManager;
  isBookmarksEnabled: boolean;
  isMessagingToolEnabled: boolean;
  sendXAPIStatement: (statement: IXAPIStatement) => void;
}

interface IActionsProps {
  bookmarkId?: number;
  params: {
    actionId: number;
  };
  type?: string;
  id: string;
  course?: string;
  link?: string;
}

const copyLinkToClipboard = (
  entity: ISearchResult,
  t: (key: string, options?: Record<string, unknown>) => string,
  toastManager: IToastManager
): void => {
  try {
    const text = t('Copy link');
    copyToClipboard(entity.shareableLink, text);

    const message = (
      <>
        <b>{t('Link copied to clipboard')}</b>
      </>
    );

    toastManager.add(message, {
      appearance: 'success',
      autoDismiss: true,
      autoDismissTimeout: 3500,
    });
  } catch (e) {
    console.error('Failed to copy to clipboard', e);
    const error = (
      <>
        <b>{t('Failed to copy link')}</b>
      </>
    );
    toastManager.add(error, {
      appearance: 'error',
      autoDismiss: false,
    });
  }
};

const getShareMenuItems = (
  messagingToolEnabled: boolean,
  t: TFunction,
  toastManager: IToastManager
): shareMenuItems[] => {
  const items = [
    // 1. Share via message
    Valamis?.eventBus && messagingToolEnabled
      ? {
          id: 1,
          title: t('Share via message'),
          onClick: (entity: ISearchResult): void => {
            eventBus.dispatch(MessagingEvents.createMessage, {
              sharingEntity: getSharingEntity(entity, t),
            });
          },
        }
      : null,
    // 2. Copy link
    {
      id: 2,
      title: t('Copy link'),
      onClick: (entity: ISearchResult): void => {
        copyLinkToClipboard(entity, t, toastManager);
      },
    },
  ];

  return items.flatMap((item) => (item ? [item] : []));
};

const ResultList: FC<IResultListProps> = (props: IResultListProps): JSX.Element => {
  const { results, toastManager, isBookmarksEnabled, isMessagingToolEnabled, sendXAPIStatement } = props;
  const { t } = useT();

  const toggleBookmark = (params: { type: string; bookmarkId: number; id: string }): Promise<void> => {
    const { type, bookmarkId, id } = params;
    return new Promise((resolve, reject) => {
      // @ts-ignore
      const entity = results.find((item) => item.id === id && item.getContentType.typeName === type);

      if (entity) {
        entity
          .toggleBookmark()
          .then(() => {
            resolve(
              toastManager.add(t(bookmarkId ? 'Unsaved' : 'Saved'), {
                appearance: 'success',
                autoDismiss: true,
              })
            );
          })
          .catch((error) => {
            console.error('Unable to toggle bookmark', error);
            reject(error);
          });
      }
    });
  };

  const download = (downloadProps: IActionsProps): void => {
    // @ts-ignore
    const entity = results.find(
      (item) => item.id === downloadProps.id && item.getContentType.typeName === downloadProps.type
    );

    let downloadHref = '';
    if (entity) {
      const contentType = getContentType(entity.className);
      if (contentType.className === Lesson.className && entity.course) {
        downloadHref = getDownloadLessonUrl(parseInt(entity.id), entity.course.id);
        // @ts-ignore
      } else if (contentType.className === File.className) {
        downloadHref = getFileDownloadLink(entity);
      } else {
        downloadHref = entity.link;
      }
      toastManager.add(t('Download started. Please wait, it could take some time...'), {
        appearance: 'info',
        autoDismiss: true,
        autoDismissTimeout: 3500,
      });
      window.open(encodeURI(downloadHref), '_blank');
      sendXAPIStatement(buildStatement(entity, Verb.downloaded));
    }
  };

  const preview = (actionsProps: IActionsProps): void => {
    const entity = results.find((item) => item.id === actionsProps.id);
    if (entity) {
      sendXAPIStatement(buildStatement(entity, Verb.previewed));
    }
  };

  const onActionHandler = (actionProps: IActionsProps): void | Promise<void> => {
    const { type, params, bookmarkId, id } = actionProps;
    const entity = results.find((item) => item.id === id && item.getContentType.typeName === type);

    // Actions order should be the following: save, share via message, copy link, download
    switch (params.actionId) {
      case 1:
        entity && toggleBookmark({ type: entity.type, bookmarkId: Number(bookmarkId), id });
        break;
      case 2:
        entity &&
          eventBus.dispatch(MessagingEvents.createMessage, {
            sharingEntity: getSharingEntity(entity, t),
          });
        break;
      case 3:
        entity && copyLinkToClipboard(entity, t, toastManager);
        break;
      case 4:
        download(actionProps);
        break;
      default:
        console.error(`Unknown action: ${params.actionId}`);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getOnClickHandler = (resultItem: ISearchResult): ((props: any) => void) => {
    const hrefTarget = resultItem.metadata.openNewTab === 'true' ? '_blank' : '_self';

    const contentType = getContentType(resultItem.className);
    if (contentType.className === Ams.className) {
      const { urn } = resultItem;
      const integrationType = 'ams';
      return (): void => {
        Valamis?.eventBus?.dispatch('player:launch', {
          content: 'integrations',
          data: { urn, integrationType },
        });
      };
    }

    if (contentType.className === Lesson.className && resultItem.newPlayer) {
      return (): void => {
        Valamis?.eventBus?.dispatch('player:launch', {
          content: 'lesson',
          data: { id: resultItem.id },
        });
      };
    }

    // eslint-disable-next-line @typescript-eslint/no-shadow, @typescript-eslint/no-explicit-any
    return (props: any): void => {
      const { link } = props;
      window.open(link, hrefTarget);
    };
  };

  const actions = {
    onActionHandler,
  };

  const list = results.map((entity: ISearchResult, index: number): JSX.Element => {
    const onClickHandler = getOnClickHandler(entity);
    const entityActions = { ...actions, onClickHandler };

    Object.assign(entity, {
      labels: {
        addBookmarkLabel: t('Save'),
        removeBookmarkLabel: t('Unsave'),
        bookmarkTitleAdd: t('Save for later'),
        bookmarkTitleRemove: t('Unsave'),
        views: t('views'),
        shareTitle: t('Share'),
        previewButtonLabel: t('Preview'),
        previewTitle: t('Preview'),
        downloadFile: t('Download'),
        closePreview: t('Close preview'),
        previousSlide: t('Previous slide'),
        nextSlide: t('Next slide'),

        assetTypeLabel: t(entity.getContentType.translationLabel),
      },
      actionItems: [],
    });

    if (entity.getContentType.bookmarkableName && isBookmarksEnabled) {
      Object.assign(entityActions, { toggleBookmark });

      entity.actionItems.push({
        id: 1,
        label: entity.bookmarkId ? t('Unsave') : t('Save'),
      });
    }

    if (entity.getContentType.isShareable && Valamis?.eventBus && isMessagingToolEnabled) {
      entity.actionItems.push({ id: 2, label: t('Share via message') });
    }

    entity.actionItems.push({ id: 3, label: t('Copy link') });

    if (entity.getContentType.isDownloadable && entity.permissions.download) {
      Object.assign(entityActions, { download });
      entity.actionItems.push({ id: 4, label: t('Download') });
    }

    if (entity.getContentType.isPreviewable) {
      Object.assign(entityActions, { preview });
    }

    const domId = getResultItemDomId(index);
    return (
      <div id={domId} key={domId}>
        <ResultItem
          entity={entity}
          actions={entityActions}
          shareMenuItems={getShareMenuItems(isMessagingToolEnabled, t, toastManager)}
        />
      </div>
    );
  });

  return <>{list}</>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default compose<any, any>(withToastManager)(ResultList);
